<template>
  <div id="app" class="myCon">
    <b-navbar transparent="true" fixed-top class="navbar">
      <template #brand>
        <b-navbar-item active tag="router-link" :to="{ path: '/' }">
          <img
              src="./assets/feishulogo.png"
              alt="Lightweight UI components for Vue.js based on Bulma"
          >
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item href="#" @click="changeNav('home')">
          首页
        </b-navbar-item>
        <b-navbar-item href="#" @click="changeNav('digitalProduct')">
          数字化产品
        </b-navbar-item>
        <b-navbar-dropdown label="解决能力">
          <b-navbar-item href="#" @click="changeNav('biTable')">
            多维表格
          </b-navbar-item>
          <b-navbar-item href="#" @click="changeNav('projectManagement')">
            项目管理
          </b-navbar-item>
          <b-navbar-item href="#" @click="changeNav('appEngine')">
            应用引擎
          </b-navbar-item>
        </b-navbar-dropdown>

        <b-navbar-dropdown label="解决方案">
          <b-navbar-item href="#" @click="changeNav('uniCase')">
            进销存
          </b-navbar-item>
          <b-navbar-item href="#" @click="changeNav('moreView')">
            更多解决方案
          </b-navbar-item>
        </b-navbar-dropdown>
        <b-navbar-item href="#" @click="changeNav('customerCase')">
          客户案例
        </b-navbar-item>
        <b-navbar-item href="#" @click="changeNav('support')">
          服务与支持
        </b-navbar-item>
        <b-navbar-item href="#" @click="changeNav('about')">
          关于我们
        </b-navbar-item>
      </template>

      <template #end>
        <b-navbar-item tag="div">
          <div class="buttons">
            <b-button class="button is-primary contactUs"
                      label="联系我们"
                      type="is-primary"
                      size="is-medium"
                      @click="isComponentModalActive = true"/>


          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <div>
      <router-view/>
    </div>
    <div class="fixedButton">
<!--      <b-tooltip
                 type="is-light" position="is-left" >
        <b-button class="buttonContact" @click="cardModal">
          <b-icon pack="uil" style="margin: 0 auto" size="is-medium" icon="headphones"></b-icon>
          <div></div>
          在线咨询
        </b-button>
        <template v-slot:content>
          <b>点我在线咨询</b>
        </template>
      </b-tooltip>-->
      <b-tooltip
          type="is-light" position="is-left" >
        <b-button class="buttonContact" @click="cardModalAff">
          <b-icon pack="uil" style="margin: 0 auto" size="is-medium" icon="headphones"></b-icon>
          <div></div>
          在线咨询
        </b-button>
        <template v-slot:content>
          <b>点我在线咨询</b>
        </template>
      </b-tooltip>
      <div></div>
      <b-tooltip
                 type="is-light" multilined  position="is-left">
        <b-button class="buttonContact" >
          <b-icon pack="uil" style="margin: 0 auto" size="is-medium" icon="phone"></b-icon>
          <div></div>
          联系我们
        </b-button>
        <template v-slot:content>
          <b>工作日9:00-19:00在线</b>, <br>电话号码：18512562566
        </template>
      </b-tooltip>
    </div>

    <div class="bottom">
      <div class="sub4">
        <span class="sub4title">致力于通过最新的数字化技术驱动</span>
        <span class="sub4title">企业实现“一体化、高效率”的协作和管理转型</span>
        <div class="buttons2">
          <b-button class="button is-primary contactUs1"
                    label="联系我们"
                    type="is-primary"
                    style="margin-top: 23px;margin-bottom: 0"
                    size="is-medium"
                    @click="isComponentModalActive = true"/>

          <b-modal
              v-model="isComponentModalActive"
              has-modal-card
              trap-focus
              :destroy-on-hide="false"
              aria-role="dialog"
              aria-label="Example Modal"
              close-button-aria-label="Close"
              aria-modal>
            <template #default="props">
              <modal-form v-bind="formProps" @close="props.close"></modal-form>
            </template>
          </b-modal>
          <b-modal
              v-model="isComponentModalActive1"
              has-modal-card
              trap-focus
              :destroy-on-hide="false"
              aria-role="dialog"
              aria-label="Example Modal"
              close-button-aria-label="Close"
              aria-modal>
            <template #default="props">
              <modal-form v-bind="formProps" @close="props.close"></modal-form>
            </template>
          </b-modal>
        </div>
      </div>
    </div>
    <div class="lastbottom">
      <div class="bottomitem">
        <div>产品</div>
        <div><a href="#" @click="changeNav('biTable')">多维表格</a></div>
        <div><a href="#" @click="changeNav('projectManagement')">项目管理</a></div>
        <div><a href="#" @click="changeNav('digitalProduct')">数字化产品</a></div>
      </div>
      <div class="bottomitem">
        <div>解决方案</div>
        <div><a href="#" @click="changeNav('moreView')">通用解决方案</a></div>
        <div><a href="#" @click="changeNav('moreView')">行业解决方案</a></div>
        <div><a href="#" @click="changeNav('moreView')">更多解决方案</a></div>
      </div>
      <div class="bottomitem">
        <div>服务与支持</div>
        <div><a href="#" @click="changeNav('support')">蓝鲸服务</a></div>
        <div><a href="#" @click="changeNav('customerCase')">客户案例</a></div>
        <!--        <div><a href="#">更多解决方案</a></div>-->
      </div>
      <div class="bottomitem">
        <div>关于我们</div>
        <div><a href="#" @click="changeNav('about')">了解蓝鲸</a></div>

      </div>
      <div class="bottomitem">
        <div>联系我们</div>
        <div><a href="#">产品咨询：18512562566</a></div>
        <div><a href="">渠道合作：18512562566</a></div>
        <div><a href="">渠道合作：1114239405@qq.com</a></div>
      </div>

    </div>
    <div class="footer1"><img src="./assets/public_security_icon.png" alt="">苏ICP备2023031821号</div>
  </div>

</template>
<script>
import ContactUs from "@/components/ContactUs.vue";
import ContactOnline from "@/components/ContactOnline.vue";
const customIconConfig = {
  customIconPacks: {
    'fas': {
      sizes: {
        'default': '',
        'is-small': 'fa-xs',
        'is-medium': 'fa-lg',
        'is-large': 'fa-2x'
      }
    },
    'ionicons': {
      sizes: {
        'default': 'is-size-5',
        'is-small': '',
        'is-medium': 'is-size-3',
        'is-large': 'is-size-1'
      },
      iconPrefix: 'ion-md-',
      internalIcons: {
        'check': 'checkmark',
        'information': 'information',
        'check-circle': 'checkmark-circle-outline',
        'alert': 'alert',
        'alert-circle': 'alert',
        'arrow-up': 'arrow-up',
        'chevron-right': 'arrow-forward',
        'chevron-left': 'arrow-back',
        'chevron-down': 'arrow-down',
        'eye': 'eye',
        'eye-off': 'eye-off',
        'menu-down': 'arrow-dropdown',
        'menu-up': 'arrow-dropup',
        'close-circle': 'close-circle-outline'
      }
    },
    'uil': {
      sizes: {
        'default': 'is-size-5',
        'is-small': '',
        'is-medium': 'is-size-3',
        'is-large': 'is-size-1'
      },
      iconPrefix: 'uil-',
      internalIcons: {
        'check': 'check',
        'information': 'information',
        'check-circle': 'checkmark-circle',
        'alert': 'exclamation',
        'alert-circle': 'exclamation-circle',
        'arrow-up': 'arrow-up',
        'chevron-right': 'angle-right',
        'chevron-left': 'angle-left',
        'chevron-down': 'arrow-down',
        'eye': 'eye',
        'eye-off': 'eye-slash',
        'menu-down': 'angle-down',
        'menu-up': 'angle-up',
        'close-circle': 'times-circle'
      }
    }
  }
}
export default {
  data() {
    return {
      isActive: '/',
      isComponentModalActive: false,
      isComponentModalActive1: false,
      formProps: {
        email: 'evan@you.com',
        password: 'testing'
      }
    }
  },
  created() {
    let path = location.href;
    this.isActive = path.substr(path.lastIndexOf('/') + 1,)
    this.$buefy.config.setOptions(customIconConfig)

  },
  components: {
    // HelloWorld
    ModalForm: ContactUs
  },
  methods: {
    changeNav(param) {
      // this.isActive = param;
      if (param == 'home') {
        this.$router.push("/")
      } else if (param == 'digitalProduct') {
        this.$router.push("/digitalProduct")
      } else if (param == 'customerCase') {
        this.$router.push("/customerCase")
      } else if (param == 'support') {
        this.$router.push("/support")
      } else if (param == 'about') {
        this.$router.push('/about')
      } else if (param == 'uniCase') {
        this.$router.push('/uniCase')
      } else if (param == 'moreView') {
        this.$router.push('/moreView')
      } else if (param == 'biTable') {
        this.$router.push('/biTable')
      } else if (param == 'projectManagement') {
        this.$router.push('/projectManagement')
      } else if (param == 'appEngine') {
        this.$router.push('/appEngine')
      }
    },
    cardModal() {
      this.$buefy.modal.open({
        parent: this,
        component: ContactOnline,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true
      })
      },
    cardModalAff() {
      window.open("https://affim.baidu.com/unique_53386854/chat?siteId=20564093&userId=53386854&siteToken=63a04eebd2aa1faa2a00a5400e7cfc38", "_blank");
      // this.$buefy.modal.open({
      //   parent: this,
      //   component: ContactOnlineAFanfan,
      //   hasModalCard: true,
      //   customClass: 'custom-class custom-class-2',
      //   trapFocus: true
      // })
    }
    }
}
</script>
<style lang="scss">
@import "./css/style.scss";
@import "https://unpkg.com/ionicons@4.5.10-0/dist/css/ionicons.min.css";
@import "https://unicons.iconscout.com/release/v2.1.11/css/unicons.css";

/*#app {*/
/*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-align: center;*/
/*  color: #2c3e50;*/
/*}*/

/*nav {*/
/*  padding: 30px;*/
/*}*/

/*nav a {*/
/*  font-weight: bold;*/
/*  color: #2c3e50;*/
/*}*/

/*nav a.router-link-exact-active {*/
/*  color: #42b983;*/
/*}*/
</style>
<style scoped>
.buttonContact {
  height: 4.6rem;
  width: 4.5rem;
  font-size: 14px;
  line-height: 18px;
}

.fixedButton {
  position: fixed;
  bottom: 100px;
  right: 40px;
}

.footer1 {
  margin-top: 80px;

  text-align: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
  line-height: 30px;
}

.lastbottom {
  display: flex;
  flex-direction: row;
}

.bottomitem {
  margin: 10px auto;
}

.bottomitem > div {
  font-weight: bold;
  font-size: 20px;
}

.bottomitem > div > a {
  font-weight: normal;
  color: gray;
  line-height: 30px;
  font-size: 15px;
}

.navbar {
  /*background-image:  linear-gradient(#3370FF,white);*/
  background-image: url("assets/ABUIABAEGAAg2s3LoAYorMjgkQYwwBY4pA8.png");
  background-size: cover;
  /*margin: 0 20px;*/
  /*height: 20px;*/
  /*padding: 0 20px;*/
  /*width: 1920px;*/
}

.bottom {

}

.buttons2 {
  justify-content: center;
  text-align: center;
}

.myCon {

  /*height: 900px;*/
  /*margin:0;*/
  /*padding: 0;*/
  /*width: 100%;*/
}

.contactUs {
  height: 38px;
  font-size: 15px;
}

.sub4 {
  padding: 60px 0;
  background-image: linear-gradient(#44ACE6, #4099FF);
  background-size: cover;
  display: block;
  margin: 0 auto 30px;
  font-size: 32px;
  color: white;
}

.sub4title {
  display: block;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  line-height: 55px;
}
</style>