<script>

import {defineComponent} from "vue";
import ModalForm from "@/components/ContactUs.vue";

export default defineComponent({
  name: "MoreView",
  data() {
    return {
      param: "digital",
      isComponentModalActive: false,
    }
  },
  components: {ModalForm},
  methods:{
    changeNav(param){
     this.$router.push(param);
    }
  }
})
</script>

<template>
  <div class="more">
    <div class="p1">
      <div class="texts">
        <h1>飞书助力企业数字化转型</h1>
        <h2>基于大量真实的客户案例，提供针对不同行业、场景的解决方案，帮助企业快速落地企业管理平台</h2>
        <div class="buttonMargin" style="margin-top: 10px">
          <div class="buttons1">
            <b-button class="button is-primary contactUs1"
                      label="预约演示"
                      type="is-primary"
                      size="is-medium"
                      @click="isComponentModalActive = true"/>

          </div>
        </div>
      </div>
    </div>
    <div class="p2">

      <div class="backgroundLiner">
        <div class="myBox">
          <div class="texts1">
            <div class="h1">行业解决方案</div>
            <!--            <div class="h2">蓝鲸为公司级、企业级客户提供7*24小时的咨询、问答服务，随时解答客户关于产品使用，系统落地等疑问</div>-->
          </div>
          <div class="bigBox">
            <div class="box box1">
              <img src="../assets/building.jpg" alt="">
              <div></div>

              <div>
                建筑业
              </div>
              <div class="text1">
                <a href="#" style="font-size: 15px" @click="changeNav('/wipView')">了解更多></a>
              </div>

            </div>
            <div class="box box1">
              <img src="../assets/manufacture_clipdrop-variants.jpeg" alt="">
              <div></div>

              <div>
                制造业
              </div>
              <div class="text1">
                <a href="#" style="font-size: 15px" @click="changeNav('/wipView')">了解更多></a>
              </div>

            </div>
            <div class="box box1">
              <img src="../assets/economic.jpg" alt="">
              <div></div>

              <div>
                金融业
              </div>
              <div class="text1">
                <a href="#" style="font-size: 15px" @click="changeNav('/wipView')">了解更多></a>
              </div>

            </div>
            <div class="box box1">
              <img src="../assets/food_clipdrop-variants.jpeg" alt="">
              <div></div>

              <div>
                餐饮业
              </div>
              <div class="text1">
                <a href="#" style="font-size: 15px" @click="changeNav('/wipView')">了解更多></a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p2">

      <div>
        <div class="myBox">
          <div class="texts1">
            <div class="h1">通用解决方案</div>
            <!--            <div class="h2">蓝鲸为公司级、企业级客户提供7*24小时的咨询、问答服务，随时解答客户关于产品使用，系统落地等疑问</div>-->
          </div>
          <div class="bigBox">
            <div class="box box1">
              <img src="../assets/jinxiaocunPic2.jpg" alt="">
              <div></div>

              <div>
                进销存
              </div>
              <div class="text1">
                <a href="#" style="font-size: 15px" @click="changeNav('/uniCase')">了解更多></a>
              </div>

            </div>
            <div class="box box1">
              <img src="../assets/projectPic2.jpg" alt="">
              <div></div>

              <div>
                项目管理
              </div>
              <div class="text1">
                <a href="#" style="font-size: 15px" @click="changeNav('projectManagement')">了解更多></a>
              </div>

            </div>
            <div class="box box1">
              <img src="../assets/manufacturePic2.jpg" alt="">
              <div></div>

              <div>
                智能制造
              </div>
              <div class="text1">
                <a href="#" @click="changeNav('/wipView')" style="font-size: 15px">了解更多></a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p3">
      <div class="texts centered1">
        <h3>没有找到想要的解决方案？</h3>
        <h3>立即联系我们</h3>
        <div class="buttonMargin" style="margin-top: 10px">
          <div class="buttons2">
            <b-button class="button is-primary contactUs1"
                      label="方案定制"
                      type="is-primary"
                      size="is-medium"
                      @click="isComponentModalActive = true"/>

            <b-modal
                v-model="isComponentModalActive"
                has-modal-card
                trap-focus
                :destroy-on-hide="false"
                aria-role="dialog"
                aria-label="Example Modal"
                close-button-aria-label="Close"
                aria-modal>
              <template #default="props">
                <modal-form v-bind="formProps" @close="props.close"></modal-form>
              </template>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.centered1{
  text-align: center;
  justify-content: center;
}
.bigBox {
  display: flex;
  margin-top: 40px;
  flex-direction: row;
  padding-bottom: 25px;
}

.backgroundLiner {
  background-image: linear-gradient(white, #E4EEFC);
}

.box1 {
  margin: 0 auto;
  width: 301px;
  height: 400px;
  padding: 0;
}

.box1 > img {
  border-radius: 10px;
  height: 200px;
  width: 100%;
}

.box1 > div {
  font-size: 32px;
  font-weight: bold;
  margin: 42px 12px;
  text-align: center;
  justify-content: center;

}

.box1 > .text1 {
  font-size: 18px;
  font-weight: normal;

}

.icons {
  display: flex;
  flex-direction: row;
  height: 10rem;
  width: 100%;
  margin: 20px auto;
  justify-content: center;
  text-align: center;
  padding: 30px 7rem;
}

.icon {
  display: block;
  width: 100%;
  margin: 0 auto;

}

.icon > div {
  font-size: 24px;
  font-weight: bold;
}

.myBox > .module {
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}

.texts1 > .h1 {
  display: block;
  font-size: 44px;
  font-weight: bold;
}

.texts1 > .h2 {
  display: block;
  font-size: 20px;
  font-weight: bold;

}

.texts1 {
  margin: 50px auto 10px;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.p2 > h1 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin: 30px 0;
}

.texts {
  padding: 10rem;
  height: 30rem;
  text-align: left;
  justify-content: center;
}

.p1 {
  background-image: url("../assets/outputBG.jpg");
  background-size: cover;
  height: 32rem;
}
.p3 {
  background-image: linear-gradient(white,#E4EEFC);
  //background-size: cover;
  height: 32rem;
}

.texts > h1 {
  font-size: 44px;
  font-weight: bold;
}
.texts > h3 {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  justify-content: center;
}
.texts > h2 {
  font-size: 32px;
//font-weight: bold;
}

.buttonMargin {
  margin-top: -80px;
  margin-bottom: 50px;
}

.buttons1 {
  justify-content: center;
  text-align: left;
}
.buttons2 {
  justify-content: center;
  text-align: center;
}

.contactUs1 {
  margin: 20px auto;
}
</style>