<template>
  <div class="uni">
    <div class="p1">
      <div class="texts">
        <h1>轻量级进销存管理系统</h1>
        <h2>进销存一体化管理，数据汇总整理每周节省6人/天，依靠实时更新的数据，备货决策周期由过去的2天降低至30分钟</h2>
        <div class="buttonMargin" style="margin-top: 10px">
          <div class="buttons1">
            <b-button class="button is-primary contactUs1"
                      label="方案定制"
                      type="is-primary"
                      size="is-medium"
                      @click="isComponentModalActive = true"/>

            <b-modal
                v-model="isComponentModalActive"
                has-modal-card
                trap-focus
                :destroy-on-hide="false"
                aria-role="dialog"
                aria-label="Example Modal"
                close-button-aria-label="Close"
                aria-modal>
              <template #default="props">
                <modal-form v-bind="formProps" @close="props.close"></modal-form>
              </template>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <div class="p2">
      <div class="icons">
        <div class="icon">
          <img src="../assets/JinxiaocunIcon1.png" alt="">
          <div>数据实时联动</div>
        </div>
        <div class="icon">
          <img src="../assets/JinxiaocunIcon2.png" alt="">
          <div>可视化展示</div>
        </div>
        <div class="icon">
          <img src="../assets/JinxiaocunIcon3.png" alt="">
          <div>重点消息推送</div>
        </div>
      </div>
      <div>
        <div class="myBox">
          <div class="texts1">
            <div class="h1">企业已有系统对接，无需进行繁重的数据迁移</div>
            <!--            <div class="h2">蓝鲸为公司级、企业级客户提供7*24小时的咨询、问答服务，随时解答客户关于产品使用，系统落地等疑问</div>-->
          </div>
          <div class="module"><img src="../assets/JinxiaocunModule1.png" alt=""></div>
        </div>
      </div>
    </div>
    <div class="p2">
      <div>
        <div class="myBox">
          <div class="texts1">
            <div class="h1">实践价值</div>
            <!--            <div class="h2">蓝鲸为公司级、企业级客户提供7*24小时的咨询、问答服务，随时解答客户关于产品使用，系统落地等疑问</div>-->
          </div>
          <div class="myBox1">
            <div class="dashTexts">
              <div class="dashTitle">一张仪表盘透视销售、采购数据</div>
              <div style="display: flex;flex-direction: row">
                <div class="digitalOl">
                  <ol>
                    <li>
                      <div class="strong">聚焦收款统计</div>

                    </li>
                    <li>
                      <div class="strong">把控采购支出</div>
                    </li>

                  </ol>
                </div>
                <div class="digitalOl">
                  <ol>
                    <li>
                      <div class="strong">关注销售走势</div>

                    </li>
                    <li>
                      <div class="strong">销采全局掌控</div>
                    </li>

                  </ol>
                </div>
              </div>
              <div class="buttonMargin" style="margin-top: 10px">
                <div class="buttons1">
                  <b-button class="button is-primary contactUs1"
                            label="预约产品展示"
                            type="is-primary"
                            size="is-medium"
                            @click="isComponentModalActive = true"/>

                </div>
              </div>
            </div>
            <div class="module"><img src="../assets/jinxiaocun1.png" alt=""></div>
          </div>
        </div>
      </div>

    </div>
    <div class="p2">
      <div>
        <div class="myBox">

          <div class="myBox1">
            <div class="module"><img src="../assets/jinxiaocunForm1.png" alt=""></div>
            <div class="dashTexts" style="margin-top: 40px">
              <div class="dashTitle" >审批集成，一键发起订单、合同等审批</div>
              <div style="display: flex;flex-direction: row">
                <div class="digitalOl">
                  <ol>
                    <li>
                      <div class="strong">进销存系统打通审批</div>

                    </li>
                    <li>
                      <div class="strong">审批记录回传表格</div>
                    </li>

                  </ol>
                </div>
                <div class="digitalOl">
                  <ol>
                    <li>
                      <div class="strong">一键发起多个审批</div>

                    </li>
                    <li>
                      <div class="strong">流程追踪有据可依</div>
                    </li>

                  </ol>
                </div>
              </div>
              <div class="buttonMargin" style="margin-top: 10px">
                <div class="buttons1">
                  <b-button class="button is-primary contactUs1"
                            label="预约产品展示"
                            type="is-primary"
                            size="is-medium"
                            @click="isComponentModalActive = true"/>


                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

    <div class="p2">
      <div>
        <div class="myBox">

          <div class="myBox1">
            <div class="dashTexts">
              <div class="dashTitle">动态追踪收付款进程、销售数据等</div>
              <div style="display: flex;flex-direction: row">
                <div class="digitalOl">
                  <ol>
                    <li>
                      <div class="strong">自动化机器人</div>

                    </li>
                    <li>
                      <div class="strong">出入库及时跟进</div>
                    </li>

                  </ol>
                </div>
                <div class="digitalOl">
                  <ol>
                    <li>
                      <div class="strong">关注重点销售数据</div>

                    </li>
                    <li>
                      <div class="strong">收付款推进至负责人</div>
                    </li>

                  </ol>
                </div>
              </div>
              <div class="buttonMargin" style="margin-top: 10px">
                <div class="buttons1">
                  <b-button class="button is-primary contactUs1"
                            label="预约产品展示"
                            type="is-primary"
                            size="is-medium"
                            @click="isComponentModalActive = true"/>


                </div>
              </div>
            </div>
            <div class="module"><img src="../assets/jinxiaocunRobot1.png" alt=""></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
.strong {
  text-align: left;
  font-size: 20px;
}

.digitalOl {
  margin-top: 2rem;
  margin-left: 20px;
  width: 12rem;
  font-size: 16px;
  display: flex;
}

.digitalOl > ol {
  list-style-type: inherit;
  line-height: 30px;

}

.digitalOl > ol li {
  margin-bottom: 25px;
}

.digitalOl > ol li > .passage {
  margin-top: 8px;
  font-size: 16px;
  width: 400px;
}

.digitalOl > ol li::marker {
  color: #4099FF;
}

.dashTitle {
  font-size: 32px;
  font-weight: bold;
}

.dashTexts {
  margin-top: 10rem;
  display: block;
}

.myBox1 {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
}

.icons {
  display: flex;
  flex-direction: row;
  height: 10rem;
  width: 100%;
  margin: 20px auto;
  justify-content: center;
  text-align: center;
  padding: 30px 7rem;
}

.icon {
  display: block;
  width: 100%;
  margin: 0 auto;

}

.icon > div {
  font-size: 24px;
  font-weight: bold;
}

.myBox > .module {
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}

.texts1 > .h1 {
  display: block;
  font-size: 44px;
  font-weight: bold;
}

.texts1 > .h2 {
  display: block;
  font-size: 20px;
  font-weight: bold;

}

.texts1 {
  margin: 50px auto 10px;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.p2 > h1 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin: 30px 0;
}

.myBox {
  /*margin-top: 50px;*/
//display: flex; margin: 50px auto; //flex-direction: row;
}

.texts {
  padding: 10rem;
  height: 30rem;
  text-align: left;
  justify-content: center;
}

.p1 {
  background-image: url("../assets/outputBG.jpg");
  background-size: cover;
  height: 32rem;
}

.texts > h1 {
  font-size: 44px;
  font-weight: bold;
}

.texts > h2 {
  font-size: 32px;
//font-weight: bold;
}

.buttonMargin {
  margin-top: -80px;
  margin-bottom: 50px;
}

.buttons1 {
  justify-content: center;
  text-align: left;
}

.contactUs1 {
  margin: 20px auto;
}

</style>

<script>

import ContactUs from "@/components/ContactUs.vue";

export default {
  name: "UniCase",
  data() {
    return {
      param: "digital",
      isComponentModalActive: false,
    }
  },
  components: {
    // HelloWorld
    ModalForm: ContactUs
  },
}


</script>