<script>
import BiTableView from "@/components/BiTableView.vue";

export default {
  name: "BiTable",
  data() {
    return {
      isComponentModalActive: false,
      isComponentModalActive1: false,
      index1: 0,
      formProps: [
        {
          images: "研发人力甘特图.png",
          title: "研发需求及人力排期",
          tagName1: "互联网",
          tagName2: "产品研发",
          intro: "记录研发需求和对应人力投入，更好规划产研项目推进计划",
          templateSrc: "https://www.feishu.cn/space/api/obj_template/create_i18n_obj/?template_i18n_id=7086009519251243010&parent_token&create_source=lark_ow&from=website_managementpage&ccm_open_type=website_managementpage",
          iframeSrc: "https://www.feishu.cn/base/bascnw7G6aYq1KEHcVepUp8zh6e/?template_preview=1&hideHeader=true&vc=true&iframeFrom=feishuOW",
        },
        {
          images: "OKR拆解及进度管理.png",
          title: "OKR 拆解与进度管理",
          tagName1: "互联网",
          tagName2: "进度管理",
          intro: "通过 OKR 做目标对齐、关键进展同步和复盘总结，持续提升组织效能",
          templateSrc: "https://www.feishu.cn/space/api/obj_template/create_i18n_obj/?template_i18n_id=6963157397544108581&parent_token&create_source=lark_ow&from=website_managementpage&ccm_open_type=website_managementpage",
          iframeSrc: "https://www.feishu.cn/base/bascnTot0rKoEHAs9ibbVtRAeUe/?template_preview=1&hideHeader=true&vc=true&iframeFrom=feishuOW",
        },
        {
          images: "漏洞提报及进度管理.png",
          title: "漏洞提报及进度管理",
          tagName1: "互联网",
          tagName2: "产品研发",
          intro: "快速登记漏洞，全局总览优先级与进度，保障产品快速优化迭代",
          templateSrc: "https://www.feishu.cn/space/api/obj_template/create_i18n_obj/?template_i18n_id=6955668995051618824&parent_token&create_source=lark_ow&from=website_managementpage&ccm_open_type=website_managementpage",
          iframeSrc: "https://www.feishu.cn/base/bascnSJAWLSBi6T5ywXhuVmBUEf/?template_preview=1&hideHeader=true&vc=true&iframeFrom=feishuOW",
        },
        {
          images: "项目验收走查表.png",
          title: "项目验收走查表",
          tagName1: "互联网",
          tagName2: "产品研发",
          intro: "记录研发需求和对应人力投入，更好规划产研项目推进计划",
          templateSrc: "https://www.feishu.cn/space/api/obj_template/create_i18n_obj/?template_i18n_id=7086008127742230530&parent_token&create_source=lark_ow&from=website_managementpage&ccm_open_type=website_managementpage",
          iframeSrc: "https://www.feishu.cn/base/bascnG3XtaK5s0thGPeBSPbIiJe/?template_preview=1&hideHeader=true&vc=true&iframeFrom=feishuOW",
        },
      ],
      formProps1: [
        {
          images: "研发项目问题跟进.png",
          title: "研发项目问题跟进",
          tagName1: "互联网",
          tagName2: "产品研发",
          intro: "项目管理设置好筛选条件，可更直观的查看待修复的问题，快速推进",
          templateSrc: "https://www.feishu.cn/space/api/obj_template/create_i18n_obj/?template_i18n_id=7138611641129861122&parent_token&create_source=lark_ow&from=website_managementpage&ccm_open_type=website_managementpage",
          iframeSrc: "https://www.feishu.cn/base/bascnFy1XgLDY5pTt1eJaOCVZPf/?template_preview=1&hideHeader=true&vc=true&iframeFrom=feishuOW",
        },
        {
          images: "优先级看板.png",
          title: "需求优先级管理",
          tagName1: "互联网",
          tagName2: "项目管理",
          intro: "在一张表中管理任务与优先级，并有负责人看板，实时查看任务进度",
          templateSrc: "https://www.feishu.cn/space/api/obj_template/create_i18n_obj/?template_i18n_id=6955015209857581598&parent_token&create_source=lark_ow&from=website_managementpage&ccm_open_type=website_managementpage",
          iframeSrc: "https://www.feishu.cn/base/bascn48JXvVHYMnDwJT9wgnheoc/?template_preview=1&hideHeader=true&vc=true&iframeFrom=feishuOW",
        },
        {
          images: "产品优先级调研.png",
          title: "KANO模型产品优先级调研",
          tagName1: "互联网",
          tagName2: "产品研发",
          intro: "快速登记漏洞，全局总览优先级与进度，保障产品快速优化迭代",
          templateSrc: "https://www.feishu.cn/space/api/obj_template/create_i18n_obj/?template_i18n_id=7065941381709316098&parent_token&create_source=lark_ow&from=website_managementpage&ccm_open_type=website_managementpage",
          iframeSrc: "https://www.feishu.cn/base/bascnudU01YqrQW0oHCFkWvGEUg/?template_preview=1&hideHeader=true&vc=true&iframeFrom=feishuOW",
        },
        {
          images: "产品走查记录.png",
          title: "产品走查记录",
          tagName1: "互联网",
          tagName2: "产品研发",
          intro: "记录走查问题，记录问题修复状态，且提供跟进人看板查看个人视图",
          templateSrc: "https://www.feishu.cn/space/api/obj_template/create_i18n_obj/?template_i18n_id=6955015214106411533&parent_token&create_source=lark_ow&from=website_managementpage&ccm_open_type=website_managementpage",
          iframeSrc: "https://www.feishu.cn/base/bascndN4nYimu19qdwktLWtG3LA/?template_preview=1&hideHeader=true&vc=true&iframeFrom=feishuOW",
        },
      ]

    }
  },
  components: {
    // HelloWorld
    ModalForm: BiTableView
  },
  methods: {
    getImages(img) {
      return require('@/assets/' + img)
    }
  },
  computed: {},
  // mounted:function () {
  //   for(var num = 0;num < this.formProps.length;num++){
  //     this.isComponentModalActive.push(false);
  // }
  // }
}


</script>

<template>
  <div class="biTable">
    <div class="p1">
      <div class="texts">
        <h1>一站式系统搭建</h1>
        <h2>一表搭建各种系统平台</h2>
      </div>
    </div>
    <div class="p2">
      <div>
        <div class="myBox">
          <div class="texts1">
            <div class="h1">可对接其他业务系统，无需进行繁重的数据迁移</div>
            <!--            <div class="h2">篮鲸为公司级、企业级客户提供7*24小时的咨询、问答服务，随时解答客户关于产品使用，系统落地等疑问</div>-->
          </div>
          <div class="module"><img src="../assets/JinxiaocunModule1.png" alt=""></div>
        </div>
      </div>
    </div>
    <div class="p2">
      <h1>多维表格快速搭建系统</h1>
      <div>
        <div class="tabSize">
          <b-tabs position="is-centered" class="block" type="is-toggle-rounded" animated>
            <b-tab-item class="centeredMo" label="日常业务">
              <div class="adv">
                <div>
        <span class="lanjingSecond">用多维表格搞定大量业务数据，
<br>
让信息（任务）管理更加方便</span>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">快速搭建，有效管理信息</div>
                        <div class="passage">
                          支持大部分业务功能，随时随地获取最新信息，<br>快速同步任务安排
                        </div>
                      </li>
                      <li>
                        <div class="strong">智能提醒，有效风险控制</div>
                        <div class="passage">
                          机器人、邮件等多种提醒方式，不错过任何重要事项，有效控制风险事项
                        </div>
                      </li>
                      <li>
                        <div class="strong">高效协同，多人在线编辑</div>
                        <div class="passage">
                          多人实时协同编辑文档，随时@同事评论，高效协同避免重复劳动
                        </div>
                      </li>
                      <li>
                        <div class="strong">附件上传，一站解决问题</div>
                        <div class="passage">
                          通过多维表格上传任何类型附件，快速与同事同步各种类型需求
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/BiTable11.png" alt="">
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" label="仪表盘" @click='change("saas")'>
              <div class="adv">
                <div>
        <span class="lanjingSecond">智能仪表盘，<br>
结构化的业务节点直观呈现</span>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">各类仪表盘，直观查看节点</div>
                        <div class="passage">
                          散落的数据集中管理，直观感受各类进度，让有价值的信息高速流转
                        </div>
                      </li>
                      <li>
                        <div class="strong">千人千面，定制不同仪表</div>
                        <div class="passage">
                          不同角色查看不同仪表，查看对自己相关的数据，方便办公
                        </div>
                      </li>
                      <li>
                        <div class="strong">流程有迹可循，轻松发现问题所在</div>
                        <div class="passage">
                          相关流程数据预警，轻松定位出问题所在
                        </div>
                      </li>
                      <li>
                        <div class="strong">表格数据打通，轻松获取数据</div>
                        <div class="passage">
                          通过多维表格数据打通，点击仪表盘直达所在的多维表格，查询具体情况
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/dashBoard.png" alt="">
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" label="多种视图" @click="change('system')">
              <div class="adv">
                <div>
        <span class="lanjingSecond">打破传统表格界限，<br>
清晰意识业务进度</span>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">多种视图，快速同步业务进度</div>
                        <div class="passage">
                          支持支持手机、电脑、平板等多平台随时随地查看，畅享沉浸式交流
                        </div>
                      </li>
                      <li>
                        <div class="strong">用数据说话，更加清晰高效</div>
                        <div class="passage">
                          每人同步自己的事项，系统自动汇总，有效跟进任务代办
                        </div>
                      </li>
                      <li>
                        <div class="strong">跨国沟通，一键翻译会议内容</div>
                        <div class="passage">
                          跨国、跨语言沟通，用飞书音视频一键翻译，无需苦恼交流障碍
                        </div>
                      </li>
                      <li>
                        <div class="strong">群聊内容自动同步会议聊天框</div>
                        <div class="passage">
                          事务繁忙没空参加会议，群聊留言即可与参会成员同步沟通
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/bitableview.png" alt="">
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" label="问卷表单" @click="change('system')">
              <div class="adv">
                <div>
        <span class="lanjingSecond">用飞书进行OA审批，<br>
提高审批透明度和公正性</span>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">审批数据集成，连接其他业务系统</div>
                        <div class="passage">
                          飞书是集成性强的企业级应用，轻松集成CRM、ERP等系统，实现信息互通和数据共享
                        </div>
                      </li>
                      <li>
                        <div class="strong">审批流程可视化，所有节点一目了然</div>
                        <div class="passage">
                          审批流程状态清晰可见，审批人随时查看审批信息，提高了审批的透明度和公正性
                        </div>
                      </li>
                      <li>
                        <div class="strong">设定规则触发，审批过程自动化流转</div>
                        <div class="passage">
                          申请单达到数量或金额时，自动触发审批流程，减少了手动操作的时间和成本
                        </div>
                      </li>
                      <li>
                        <div class="strong">多样化审批方式，满足企业多种需求</div>
                        <div class="passage">
                          多种审批方式，如单人审批、多人审批、依次审批等，满足不同的审批需求
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/sheetview.png" alt="">
                </div>
              </div>
            </b-tab-item>
          </b-tabs>

        </div>
      </div>
    </div>
    <div class="p2">
      <h1>案例能力展示</h1>
      <div class="templates">
        <div v-for="(items,index) in formProps" :id="index"  :key="index">
          <div class="box box1" @mouseover="index1 = index" @click="isComponentModalActive = true">

            <img :src="getImages(items.images)" alt="">
            <div></div>
            <b-field>
              <b-tag rounded type="is-warning is-light">{{ items.tagName1 }}</b-tag>
              <b-tag rounded type="is-primary is-light">{{ items.tagName2 }}</b-tag>
            </b-field>
            <div>
              {{ items.title }}
            </div>
            <div class="text1">
              {{ items.intro }}<br>
              <a href="#" style="font-size: 15px" >了解更多></a>
            </div>

          </div>
          <b-modal
              v-model="isComponentModalActive"
              has-modal-card
              trap-focus
              :destroy-on-hide="false"
              aria-role="dialog"
              aria-label="Example Modal"
              full-screen
              close-button-aria-label="关闭"
              aria-modal>
            <template #default="props">
              <modal-form v-bind="formProps[index1]" @close="props.close"></modal-form>
            </template>
          </b-modal>
        </div>
      </div>
      <div class="templates">
        <div v-for="(items,index) in formProps1" :id="index"  :key="index">
          <div class="box box1" @mouseover="index1 = index" @click="isComponentModalActive1 = true">

            <img :src="getImages(items.images)" alt="">
            <div></div>
            <b-field>
              <b-tag rounded type="is-warning is-light">{{ items.tagName1 }}</b-tag>
              <b-tag rounded type="is-primary is-light">{{ items.tagName2 }}</b-tag>
            </b-field>
            <div>
              {{ items.title }}
            </div>
            <div class="text1">
              {{ items.intro }}<br>
              <a href="#" style="font-size: 15px">了解更多></a>
            </div>

          </div>
          <b-modal
              v-model="isComponentModalActive1"
              has-modal-card
              trap-focus
              :destroy-on-hide="false"
              aria-role="dialog"
              aria-label="Example Modal"
              full-screen
              close-button-aria-label="关闭"
              aria-modal>
            <template #default="props">
              <modal-form v-bind="formProps1[index1]" @close="props.close"></modal-form>
            </template>
          </b-modal>
        </div>
      </div>
    </div>


  </div>
</template>

<style scoped>
.centeredMo{
  margin: 0 auto;
}
.myBox > .module {
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}

.texts1 > .h1 {
  display: block;
  font-size: 44px;
  font-weight: bold;
}

.texts1 > .h2 {
  display: block;
  font-size: 20px;
  font-weight: bold;

}

.texts1 {
  margin: 50px auto 10px;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.templates {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  padding:0 1rem;
  justify-content: center;
  //text-align: center;
}
.templates > div {
  margin: 0.5rem;
}

.box1 {
  margin: 0 auto;
  width: 301px;
  height: 400px;
  padding: 0;
  cursor: pointer;
}

.box1 > div {
  font-size: 20px;
  font-weight: bold;
  margin: 8px 12px;

}

.box1 > .text1 {
  font-size: 18px;
  font-weight: normal;

}

.box1 > img {
  border-radius: 10px;
}

.strong {
  font-weight: bold;
  font-size: 20px;
  width: 700px;

}


.digitalOl {
  margin-top: 2rem;
  width: 32rem;
  font-size: 16px;
}

.digitalOl > ol {
  list-style-type: inherit;
  line-height: 30px;

}

.digitalOl > ol li {
  margin-bottom: 25px;
}

.digitalOl > ol li > .passage {
  margin-top: 8px;
  font-size: 16px;
  width: 400px;
}

.digitalOl > ol li::marker {
  color: #4099FF;
}

.contactUs1 {
  margin: 20px auto;
}

.adv {
  margin-left: 5.5rem;
  display: flex;
  flex-direction: row;
}

.lanjingSecond {
  display: block;
  font-size: 30px;
  font-weight: bold;
  line-height: 40px;
}

.p2 > h1 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin: 30px 0;
}

.subA {
  margin-top: 1rem;
}


.tabSize {
  /*padding: 0 30rem;*/
}

.p1 {
  background-image: url("../assets/outputBG2.png");
  background-size: cover;
  height: 30rem;
}

.texts {
  padding: 11rem;
  height: 30rem;
  text-align: center;
  justify-content: center;
}

.texts > h1 {
  font-size: 44px;
  font-weight: bold;
}

.texts > h2 {
  font-size: 38px;
}
</style>