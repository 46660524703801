<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "WIPView"
})
</script>

<template>
  <div>
    <div class="p1">
      <img src="../assets/rocket.png" alt="">
      <div>敬请期待...</div>
    </div>
  </div>
</template>

<style scoped>
.p1 {
  background-image: url("../assets/outputBG.jpg");
  background-size: cover;
  height: 30rem;
  text-align: center;
  align-content: center;
  font-size: 3rem;
}
</style>