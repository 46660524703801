<script>
import {defineComponent} from 'vue'
import ModalForm from "@/components/ContactUs.vue";

export default defineComponent({
  name: "MdhcView",
  components: {ModalForm},
  data() {
    return {
      scrollTop: 0,
      isComponentModalActive: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.yyy, true);
  },

  methods: {


    yyy() {
      this.scrollTop = document.documentElement.scrollTop;
      console.log(this.scrollTop);
      if (this.scrollTop > 550 && this.scrollTop < 1730) {
        document.getElementById("box").style.position = "fixed";
        document.getElementById("box").style.top = "80px";
      }
      if (this.scrollTop < 550) {
        document.getElementById("box").style.position = "";
        document.getElementById("box").style.top = "610px";

      }
      if (this.scrollTop > 1730) {
        document.getElementById("box").style.position = "";
        document.getElementById("box").style.top = "1800px";

      }
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.yyy, true);
  }

})
</script>

<template>
  <div class="mdhc">
    <b-modal
        v-model="isComponentModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        close-button-aria-label="Close"
        aria-modal>
      <template #default="props">
        <modal-form v-bind="formProps" @close="props.close"></modal-form>
      </template>
    </b-modal>
    <div class="header">
      <div class="headerContent">
        <div class="headerText">
          <h1 class="headerH1">美东汇成生命科技：踏上数字化快车道， <br>实现品牌效益双丰收</h1>
          <div class="subtitles">
            <div class="subtitle">
              <h2>洞察组织</h2>
              <span>望闻问切 <br>
洞察透明化组织</span>
            </div>
            <div class="subtitle">
              <h2>推进业务</h2>
              <span>新工具和新思想
                <br>
                加速业务高质推进</span>
            </div>
            <div class="subtitle">
              <h2>方法赋能</h2>
              <span>医药产品知识管理新方法，赋能业务团队加速知识流转</span>
            </div>
          </div>
        </div>
        <img class="headerPic" src="../assets/meidongPic.png" alt="">
      </div>
    </div>
    <div class="bigSection">
      <div class="bigSection1">
        <div class="section2">
          <h3>公司介绍</h3>
          <div class="textIntro">美东汇成是一家以研发为导向，致力于打造实验室自动化设备及精密耗材的“一站式”
            智造及服务平台。公司汇聚了逾百人的专业研发、设计团队以及制造领域的工程师。
            我们坚持以技术为导向的匠人匠心精神，如履薄冰的质量意识，助力生命科学设备工具和
            精密耗材国产化和全球化。公司自成立以来，已先后投资5亿多元，目前已经拥有苏州、重庆、中山、惠州4个生产基地，厂房面积超过120,000平米，万级和十万级洁净厂房面积逾30，000平米，团队曾服务于顶尖国际生命科学公司，富有行业经验和专业定制能力，可以帮助您快速完成设计、产品生产、质量控制和物流的全过程，协助您短期内交付您需要的个性化的产品。我们的质量体系可以应对您不断变化的需要，提供严格的研发、生产和质量控制过程。中国智造，世界品质，美东汇成人愿以“匠人匠心”的态度，为国产品牌在
            全球市场拥有一席之地倾尽全力。
          </div>

        </div>
        <div class="section2">
          <h3>美东汇成使用多维表格解决工单系统</h3>
          <div class="textIntro">美东汇成利用多维表格搭建信息化工单系统，工单解决率可视化，利用多维表格有以下好处: <br>
            <ul>
              <br>
              <li>
               灵活性和可自定义性：多维表格系统允许根据工单系统的需求进行自定义设置，包括字段、数据类型、校验规则等。这意味着可以根据具体的业务需求灵活地定义和管理工单信息，满足不同部门和业务流程的要求。
              </li>
              <li>
                数据关联性和可追溯性：通过多维表格系统，可以将不同工单相关的数据建立关联，形成完整的数据链条。这有助于提高信息化工单系统的数据可追溯性，减少数据冗余和重复录入的问题，提高数据的准确性和一致性。

              </li>
              <li>
                基于多维表格的信息化工单系统可以方便地进行报表和统计分析。通过对工单数据的分析，可以了解工单的处理情况、工时统计、问题分类分析等。这为管理决策提供了有效的依据和支持。
              </li>
              <li>
                多维表格系统可以支持实时协作和通知提醒。当工单状态发生变化或有新的操作需要处理时，系统可以通过即时通知功能提醒相关人员，确保及时响应和处理。
              </li>
              <li>
                相对于传统的工单系统，基于多维表格的信息化工单系统在部署和维护方面更加便捷和经济，不需要复杂的软件安装和服务器配置，降低了成本和维护的复杂性。
              </li>
            </ul>
          </div>
          <p class="bitableP">
            <iframe class="bitable"
                    src="https://vjr0v6eprl.feishu.cn/base/DK5zbigaAaf5Blsg0jackjDknah?table=blkHpHWT589Lcizv"
                    frameborder="0"></iframe>
          </p>
          <p>
            <a href="https://vjr0v6eprl.feishu.cn/base/DK5zbigaAaf5Blsg0jackjDknah?table=blkHpHWT589Lcizv"
               target="_blank">多维表格demo查看</a>
          </p>
        </div>
      </div>
      <div class="subBox">
        <div class="box1" style="position:"></div>
        <div class="box box1" id="box">
          <div class="innerBox">
            <span class="h11">篮鲸数字</span>
            <span class="h12">值得信赖的企业效能顾问</span>
          </div>
          <b-button type="is-primary" @click="isComponentModalActive = true" class="contactUs2" outlined>联系我们</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
ul{
  list-style-type: inherit;
  line-height: 30px;
}

ul li::marker {
  color: #4099FF;
}

.contactUs2{
  margin: 42px auto;
  text-align: center;
  justify-content: center;
  width: 310px;
  align-items: center;
  justify-items: center;
}

.innerBox{
  background-size: cover;
  background-image: url(../assets/ABUIABAEGAAgiZ7goAYo2IXd7AYwoAs4pAM.png);
  text-align: left;
  border-radius: 8px;
  padding: 10px 30px;
  width: 324px;
  height: 150px;

}
.subBox{
  width: 950px;
  margin-left: 150px;
  //margin-right: 20px;
}
.innerBox > .h11{
  margin-top: 25px;
  line-height: 45px;
  display: block;
  color: #133B9B;
  font-size: 25px;
}
.innerBox > .h12{
  display: block;
  font-size: 18px;
  color: #133B9B;

}

.headerPic {
  height: 88%;
  //width: 90%;
//width: 100%;
}

.bigSection{
  display: flex;
}
.header {
  background-color: #F6F9FF;
  height: 540px;
  display: flex;
}

.box1 {
  padding: 0;
  position: absolute;
  right: 30px;
  top: 610px;
  width: 324px;
  height: 250px;
  justify-content: center;
  text-align: center;
}

h3 {
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
}

h2 {
  color: #245BDB;
  font-size: 36px;
  line-height: 48px;
  font-weight: bold;
}

.textIntro {
  margin: 10px 20px;
  font-size: 17px;
  line-height: 28px;
}

.section2 {
  margin: 10px 80px;
}

.subtitle {
  width: 180px;
}

.subtitle > span {
  font-size: 14px;
  line-height: 20px;
  color: #646a73;
}

.headerContent {
  display: flex;
  padding: 100px 0;
  margin: 0 auto;
  align-items: center;
  align-content: center;
}

.subtitle {

}

.subtitles {
  display: flex;
}

.headerH1 {
  margin: 0 auto;
  //width: 550px;
}

.headerText {
  display: block;
}

.bitableP {
  margin: 10px auto;
  align-content: center;
  text-align: center;
}


.bitable {
  //width: 1200px;
  width: 100%;
  height: 800px;
  margin: 0 auto;
  align-content: center;
  text-align: center;
}

.mdhc {
//margin: 10px 10rem;
}

h1 {
  font-size: 30px;
  font-weight: bold;
}
</style>