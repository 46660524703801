<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "AppEngineView"
})
</script>

<template>
  <div class="appEngine">
    <div class="p1">
      <div class="texts">
        <h1>飞书应用引擎</h1>
        <h2>使用零/低代码，轻松搭建个性化管理应用</h2>
      </div>
    </div>
    <div class="p2 backgroundBT">
      <div>
        <div class="myBox">
          <div class="texts1">
            <div class="h1">认识飞书应用引擎</div>
            <div class="h2">「飞书应用引擎」是一个aPaaS(低代码开发)平台。

            </div>
            <div class="h2">aPaaS为企业提供了一个"可用于开发、部署和管理应用程序"的云环境。
            </div>
            <div class="h2">
              借助aPaaS,开发者甚至无需代码,就能
              快速搭建在线管理系统。
            </div>
          </div>
          <div class="module"><img src="../assets/appEngineText.png" alt=""></div>
        </div>
      </div>
    </div>
    <div class="p2 backgroundTB">
      <h1>飞书应用引擎优势</h1>
      <div>
        <div class="tabSize">
          <b-tabs position="is-centered" class="block" type="is-toggle-rounded" animated>
            <b-tab-item class="centeredMo" label="aPaaS和传统开发">
              <div class="adv">
                <div>
        <span class="lanjingSecond">无需专业工程师，
<br>
快速搭建管理系统</span>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">移动互联网时代,企业的业务快速更迭与数字化依赖日渐加剧。</div>
                        <!--                        <div class="passage">-->
                        <!--                          支持大部分业务功能，随时随地获取最新信息，<br>快速同步任务安排-->
                        <!--                        </div>-->
                      </li>
                      <li>
                        <div class="strong">内部的管理系统,面临愈发显著的高成本投入与数据孤岛现象。</div>
                        <!--                        <div class="passage">-->
                        <!--                          机器人、邮件等多种提醒方式，不错过任何重要事项，有效控制风险事项-->
                        <!--                        </div>-->
                      </li>
                      <li>
                        <div class="strong">和传统开发相比,使用aPaaS开发应用的周期大大缩短。</div>
                        <!--                        <div class="passage">-->
                        <!--                          多人实时协同编辑文档，随时@同事评论，高效协同避免重复劳动-->
                        <!--                        </div>-->
                      </li>
                      <li>
                        <div class="strong">在通用性、成本投入、数据联通、效率、灵活与稳
                          定性上,都有了大幅提升。
                        </div>
                        <!--                        <div class="passage">-->
                        <!--                          通过多维表格上传任何类型附件，快速与同事同步各种类型需求-->
                        <!--                        </div>-->
                      </li>
                    </ol>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/appEngineVantage1.png" alt="">
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" label="aPaas和SaaS" @click='change("saas")'>
              <div class="adv">
                <div>
        <span class="lanjingSecond">在aPaaS平台,搭建所得即是SaaS系统,<br>
且支持灵活迭代</span>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">SaaS软件通过封装固定功能,开放给用户在线使用。因此存在局限性</div>
                        <!--                        <div class="passage">-->
                        <!--                          散落的数据集中管理，直观感受各类进度，让有价值的信息高速流转-->
                        <!--                        </div>-->
                      </li>
                      <li>
                        <div class="strong">SaaS软件在收到定制化需求时,需要较长的开发周期</div>
                        <!--                        <div class="passage">-->
                        <!--                          不同角色查看不同仪表，查看对自己相关的数据，方便办公-->
                        <!--                        </div>-->
                      </li>
                      <li>
                        <div class="strong">SaaS软件在面向大量用户,需求的响应速度较慢,解决周期也较长</div>
                        <!--                        <div class="passage">-->
                        <!--                          相关流程数据预警，轻松定位出问题所在-->
                        <!--                        </div>-->
                      </li>
                      <li>
                        <div class="strong">而在aPaaS平台,搭建所得是各种各样的SaaS系统,且支持灵活迭代。它的能力,恰恰能够解决
                          SaaS的
                          短板
                        </div>
                        <!--                        <div class="passage">-->
                        <!--                          通过多维表格数据打通，点击仪表盘直达所在的多维表格，查询具体情况-->
                        <!--                        </div>-->
                      </li>
                    </ol>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img style="height: 450px;width: 900px" src="../assets/appEngineVantage2.png" alt="">
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" label="飞书应用引擎" @click="change('system')">
              <div class="adv">
                <div>
        <span class="lanjingSecond">「飞书应用引擎」作为aPaaS平台,<br>
能够让用户能够自由定义</span>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">组合「数据结构」「可视化配置」
                          权限配置」
                        </div>
                        <!--                        <div class="passage">-->
                        <!--                          支持支持手机、电脑、平板等多平台随时随地查看，畅享沉浸式交流-->
                        <!--                        </div>-->
                      </li>
                      <li>
                        <div class="strong">省力、自由、快捷地搭建出个性化的管理应用</div>
                        <!--                        <div class="passage">-->
                        <!--                          每人同步自己的事项，系统自动汇总，有效跟进任务代办-->
                        <!--                        </div>-->
                      </li>

                    </ol>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/appEngineVantage3.png" alt="">
                </div>
              </div>
            </b-tab-item>

          </b-tabs>

        </div>
      </div>
    </div>
    <div class="p2 backgroundBT">
      <h1>核心能力介绍</h1>
      <div>
        <div class="tabSize">
          <b-tabs position="is-centered" class="block" type="is-toggle-rounded" animated>
            <b-tab-item class="centeredMo" label="自定义「数据模型」">
              <div class="adv1">
                <div>
                  <span
                      class="lanjingSecond">提供20+种常见和高级字段,不写代码也能轻松实现数据间的关联,数据结构完全自定义</span>

                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/appEngineCap1.png" alt="">
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" label="可视化「应用页面」">
              <div class="adv1">
                <div>
                  <span
                      class="lanjingSecond">提供多种页面框架,不限移动端和桌面端。6大类组件,让开发者可视化搭建出页面与交互效果</span>

                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/appEngineCap2.png" alt="">
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" label="自动化「流程管理」">
              <div class="adv1">
                <div>
        <span class="lanjingSecond">通过流程触发器&流程节点,各类复杂的工作流和审批流均可实现, <br>
          以及更多自动化操作(读写业务数据、调用外部服务、发送送Bot消息、执行定时任务等)。
</span>

                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/appEngineCap3.png" alt="">
                </div>
              </div>
            </b-tab-item>


          </b-tabs>

        </div>
      </div>
    </div>
    <div class="p2 backgroundTB">
      <h1>核心能力介绍</h1>
      <div>
        <div class="tabSize">
          <b-tabs position="is-centered" class="block" type="is-toggle-rounded" animated>
            <b-tab-item class="centeredMo" label="自定义「数据模型」">
              <div class="adv1">
                <div>
                  <span
                      class="lanjingSecond">提供20+种常见和高级字段,不写代码也能轻松实现数据间的关联,数据结构完全自定义</span>

                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/appEngineCap1.png" alt="">
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" label="可视化「应用页面」">
              <div class="adv1">
                <div>
                  <span
                      class="lanjingSecond">提供多种页面框架,不限移动端和桌面端。6大类组件,让开发者可视化搭建出页面与交互效果</span>

                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/appEngineCap2.png" alt="">
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" label="自动化「流程管理」">
              <div class="adv1">
                <div>
        <span class="lanjingSecond">通过流程触发器&流程节点,各类复杂的工作流和审批流均可实现, <br>
          以及更多自动化操作(读写业务数据、调用外部服务、发送送Bot消息、执行定时任务等)。
</span>

                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/appEngineCap3.png" alt="">
                </div>
              </div>
            </b-tab-item>


          </b-tabs>

        </div>
      </div>
    </div>
    <div class="p2 backgroundBT">
      <h1>典型应用场景</h1>
      <div>
        <div class="tabSize">
          <b-tabs position="is-centered" class="block" type="is-toggle-rounded" animated>
            <b-tab-item class="centeredMo" label="主数据管理">
              <div class="adv1">
                <div>
                  <span class="lanjingSecond">「飞书应用引擎」打破企业内部数据孤岛
</span>
                  <div class="sub">自定义企业主数据管理门户,支持管理及查询公司在法务、财务、房产、采购等任意领域的主数据
                  </div>

                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img class="img3" src="../assets/appEngineS1.png" alt="">
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" label="项目/任务管理">
              <div class="adv1">
                <div>
                  <span class="lanjingSecond">「飞书应用引擎」提升通用管理的便捷性与灵活性
</span>
                  <div class="sub">及时推动项目的日常管理维护、项目任务分解等,帮助团队实实时掌握项目动态,跟踪任务进度
                  </div>

                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img class="img3" src="../assets/appEngineS2.png" alt="">
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" label="门店/设备巡检">
              <div class="adv1">
                <div>
        <span class="lanjingSecond">「飞书应用引擎」适应细分业务,敏捷开发与迭代
</span>
                  <div class="sub">自主管理门店/设备信息、配置巡检规则,系统能够每日生成巡检任务,自动发送消息提醒。</div>
                  <div class="sub">巡检情况实时同
                    步管理员端,自动生成统计分析报表。管理者可以及时跟进巡检任务,跟踪缺陷问题处理。
                  </div>

                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img class="img3" src="../assets/appEngineS3.png" alt="">
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" label="专利申请">
              <div class="adv1">
                <div>
        <span class="lanjingSecond">「飞书应用引擎」应用随搭随用,小众场景也不怕


</span>
                  <div class="sub">员工个人即可提报专利,可添加多个发明人、自定义奖金分配比例;</div>
                  <div class="sub">专利BP可在飞书中审批提报申请,在管理后台总览专利提报情况。</div>


                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img class="img3" src="../assets/appEngineS4.png" alt="">
                </div>
              </div>
            </b-tab-item>


          </b-tabs>

        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.img3{
  height: 400px;
}
.sub {
  font-size: 25px;
  margin-top: 10px;
}

.backgroundTB {
  background-image: linear-gradient(#E4EEFC, white);
}

.backgroundBT {
  background-image: linear-gradient(white, #E4EEFC);
}

.adv img {
  height: 390px;
}

.centeredMo {
  margin: 0 auto;
}

.myBox > .module {
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}

.texts1 > .h1 {
  display: block;
  font-size: 44px;
  font-weight: bold;
}

.texts1 > .h2 {
  display: block;
  font-size: 20px;
  font-weight: bold;

}

.texts1 {
  margin: 50px auto 10px;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.templates {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0 6rem;
  justify-content: center;
//text-align: center;
}

.templates > div {
  margin: 0.5rem;
}

.box1 {
  margin: 0 auto;
  width: 301px;
  height: 400px;
  padding: 0;
  cursor: pointer;
}

.box1 > div {
  font-size: 20px;
  font-weight: bold;
  margin: 8px 12px;

}

.box1 > .text1 {
  font-size: 18px;
  font-weight: normal;

}

.box1 > img {
  border-radius: 10px;
}

.strong {
  font-weight: bold;
  font-size: 20px;
  width: 500px;

}


.digitalOl {
  margin-top: 2rem;
  width: 32rem;
  font-size: 16px;
}

.digitalOl > ol {
  list-style-type: inherit;
  line-height: 30px;

}

.digitalOl > ol li {
  margin-bottom: 25px;
}

.digitalOl > ol li > .passage {
  margin-top: 8px;
  font-size: 16px;
  width: 400px;
}

.digitalOl > ol li::marker {
  color: #4099FF;
}

.contactUs1 {
  margin: 20px auto;
}

.adv {
  margin-left: 5.5rem;
  display: flex;
  flex-direction: row;
}

.adv1 {
//margin-left: 5.5rem; margin: 30px auto;
  text-align: center;
  display: block;
}

.adv1 img {
  margin-top: 10px;
  height: 600px;
}

.lanjingSecond {
  display: block;
  font-size: 30px;
  font-weight: bold;
  line-height: 30px;
}

.p2 > h1 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  padding: 30px 0;
}

.subA {
  margin-top: 1rem;
}


.tabSize {
  /*padding: 0 30rem;*/
}

.p1 {
  background-image: url("../assets/outputBG2.png");
  background-size: cover;
  height: 30rem;
}

.texts {
  padding: 11rem;
  height: 30rem;
  text-align: center;
  justify-content: center;
}

.texts > h1 {
  font-size: 44px;
  font-weight: bold;
}

.texts > h2 {
  font-size: 38px;
}
</style>