<template>
  <div class="home">
    <!--    <img alt="Vue logo" src="../assets/logo.png">-->

    <b-carousel :arrow="arrow"
                :repeat="true"
                :arrow-hover="arrowHover"
                :icon-pack="iconPack"
                :icon-prev="iconPrev"
                :icon-next="iconNext"
                :icon-size="iconSize"
                :indicator="false"
                :autoplay="true"
                :interval="5000"
                >
      <b-carousel-item>
        <section :class="`hero is-medium`">
          <div class="section12">
            <div class="subT">
              <span class="lanjing">现在使用飞书</span>
              <span class="headTitle" id="head">限时体验30天企业效能顾问服务</span>
              <span class="headTitle">企业效能顾问专注于提升企业效率，价值落地，为企业数字化转型添砖加瓦</span>
              <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
            </div>
            <b-button @click="isComponentModalActive = true" class="contactUs3" type="is-primary">立即体验</b-button>
            <div class="subsection">
              <div class="centered" style="top: 13rem">
                <!--          <img class="bgimage" src="../assets/ABUIABAEGAAgj-25oAYogLKDYzDUAziEAg.png" alt="">-->
                <div class="logo"><img src="../assets/ABUIABAEGAAgj-25oAYowK25nwQwRjhF.png" alt="" sizes="" srcset=""></div>
                <span class="subtitle1">企业效能提升</span>
              </div>
              <div class="centered" style="top: 13rem">
                <!--          <img class="bgimage" src="../assets/ABUIABAEGAAgj-25oAYogLKDYzDUAziEAg.png" alt="">-->

                <div class="logo"><img src="../assets/icon2.png" alt="" sizes="" srcset=""></div>
                <span class="subtitle1">三方系统集成</span>

              </div>
              <div class="centered" style="top: 13rem">


                <div class="logo"><img src="../assets/icon3.png" alt="" sizes="" srcset=""></div>
                <span class="subtitle1">体验飞书爽点</span>

              </div>
            </div>
          </div>
        </section>
      </b-carousel-item>
      <b-carousel-item>
        <section :class="`hero is-medium`">
          <div class="section1">
            <div class="subT">
              <span class="lanjing">选择蓝鲸</span>
              <span class="headTitle" id="head">可信赖的企业效能顾问</span>
              <span class="headTitle">为您的企业提供飞书上线的保驾护航服务，效率价值落地，成为您的信任效能顾问</span>
              <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
            </div>
            <div class="subsection">
              <div class="centered">
                <!--          <img class="bgimage" src="../assets/ABUIABAEGAAgj-25oAYogLKDYzDUAziEAg.png" alt="">-->
                <div class="logo"><img src="../assets/ABUIABAEGAAgj-25oAYowK25nwQwRjhF.png" alt="" sizes="" srcset=""></div>
                <span class="subtitle1">飞书合作伙伴</span>
              </div>
              <div class="centered">
                <!--          <img class="bgimage" src="../assets/ABUIABAEGAAgj-25oAYogLKDYzDUAziEAg.png" alt="">-->

                <div class="logo"><img src="../assets/icon2.png" alt="" sizes="" srcset=""></div>
                <span class="subtitle1">系统集成开发</span>

              </div>
              <div class="centered">


                <div class="logo"><img src="../assets/icon3.png" alt="" sizes="" srcset=""></div>
                <span class="subtitle1">服务超1000+客户</span>

              </div>
            </div>
          </div>
        </section>
      </b-carousel-item>

    </b-carousel>
    
    <div class="section2">

      <div class="subT subA">
        <div class="title1">
          产品与服务
        </div>
        <div class="tabSize">
          <b-tabs position="is-centered" class="block" type="is-toggle-rounded" animated>
            <b-tab-item label="数字化产品" class="centeredMo" @click='change("digital")'>
              <div class="adv">
                <div>
        <span class="lanjingSecond">用飞书打造一站式数字化办公平台<br>
构建一体化、高效率的工作协同体验</span>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        飞书是字节跳动旗下企业协作和管理平台，<span
                          class="strong">将即时沟通、日历、音视频会议、云文档、云盘、邮箱和工作台</span>深度整合，全方位为企业提升办公效率。
                      </li>
                      <li>
                        蓝鲸为企业提供飞书全系列产品的落地服务，满足企业在<span class="strong">日常协同办公、知识沉淀、OA审批、项目管理、OKR管理等办公、业务场景</span>的协作管理需求。
                      </li>
                    </ol>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/feishuUI.png" alt="">
                </div>
              </div>
            </b-tab-item>

            <b-tab-item label="系统集成" class="centeredMo" @click="change('system')">
              <div class="adv">
                <div>
        <span class="lanjingSecond">打破数据孤岛，实现企业<br>
内部业务系统互联、自动化
</span>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        企业会使用大量的内部业务管理系统，这些<span class="strong">业务系统之间相互依赖、相互协同，但又相互“孤立”。</span>业务流程的衔接，经常需要靠人工介入，效率低且一致性无法保证。
                      </li>
                      <li>
                        蓝鲸支持<span class="strong">泛微、金蝶、用友、自研</span>等多个业务系统打通，帮助企业实现<span class="strong">OA统一待办集成、ERP业财数据互通、人事与招聘系统、项目管理与任务系统</span>等链接场景，赋能一线业务提升。
                      </li>
                    </ol>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/feishuAbli.png"  alt="">
                </div>
              </div>
            </b-tab-item>
          </b-tabs>
          <div class="buttonMargin">
            <div class="buttons1">
              <b-button class="button is-primary contactUs1"
                        label="预约演示"
                        type="is-primary"

                        size="is-medium"
                        @click="isComponentModalActive = true"/>


            </div>
          </div>
        </div>
      </div>
      <div class="section3">
        <div class="sub3">
          <span class="sub3title">无需代码开发</span>
          <span class="headTitle3">搭建企业专属数字化办公协作平台</span>
          <div class="subsection1">
            <div class="centered1">
              <div class="logo"><img src="../assets/icon_1.png" alt="" sizes="" srcset="">
              </div>
              <span class="centeredTitle">飞书合作伙伴</span>
              <span
                  class="centeredWord">大部分传统的制造业、建筑业业务流程虽然大致相同，但都会有一些个性化的差异，导致市面上的工具不能完全适配业务，我们为企业提供个性化定制，满足企业的差异化需求</span>
            </div>
            <div class="centered1">
              <div class="logo"><img src="../assets/computorlogo1.png" alt="" sizes="" srcset="">
              </div>
              <span class="centeredTitle">系统集成开发</span>
              <span
                  class="centeredWord">蓝鲸以一站式数字化办公平台-飞书为底座，基于飞书的开放能力打造企业专属业务系统，满足企业一体化办公协同需求，将割裂的协作体验整合到一个工具中</span>
            </div>

            <div class="centered1">
              <div class="logo"><img src="../assets/sthlogo2.png" alt="" sizes="" srcset="">
              </div>
              <span class="centeredTitle">服务超1000+客户</span>
              <span
                  class="centeredWord">在服务1000+的客户中积累了大量的系统建设经验，同时深耕行业，了解各行业管理现状，为客户提供最适配、全面地场景落地方案</span>
            </div>
          </div>
          <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
        </div>
        <div class="buttonMargin" style="margin-top: 30px">
          <div class="buttons1">
            <b-button class="button is-primary contactUs1"
                      label="方案定制"
                      type="is-primary"
                      size="is-medium"
                      @click="isComponentModalActive = true"/>

            <b-modal
                v-model="isComponentModalActive"
                has-modal-card
                trap-focus
                :destroy-on-hide="false"
                aria-role="dialog"
                aria-label="Example Modal"
                close-button-aria-label="Close"
                aria-modal>
              <template #default="props">
                <modal-form v-bind="formProps" @close="props.close"></modal-form>
              </template>
            </b-modal>
          </div>
        </div>
      </div>
      <div class="section4">
        <div class="sub3">
          <span class="sub3title">理论走向实践</span>
          <span class="headTitle3">助力1000+客户成功实现数字化转型</span>
          <div class="myBox">
            <div class="box box1">
              <img class="img1" src="../assets/sandiejiPic.png" alt="">
              <div></div>
              <img class="img2" src="../assets/sandiejiLogocn.png" alt="">
              <div>
                智能制造企业 <br>
                多地办公新升级
              </div>
              <div class="text1">
                利用多维表格进行资产管理，使用多维表格扫码盘点资产，快速申请领用；
                <br>
                <a href="#" style="font-size: 15px" @click="changeNav('/wipView')">了解更多></a>
              </div>
            </div>
            <div class="box box1">
              <img class="img1" src="../assets/lingboPic.png" alt="">
              <div></div>
              <img class="img2" src="../assets/lingboLogo.png" alt="">
              <div>
                智能制造企业 <br>
                智能管理车辆数据
              </div>
              <div class="text1">
                用飞书帮助凌博电子建立更高效的工作体系，利用多维表格管理车辆使用信息，抓取最新数据；
                <br>
                <a href="#" style="font-size: 15px" @click="changeNav('/wipView')">了解更多></a>
              </div>

            </div>
            <div class="box box1">
              <img class="img1" src="../assets/meidongPic.png" alt="">
              <div></div>
              <img class="img2" src="../assets/meidongLogo.png" alt="">
              <div>
                收集信息自动化 <br>
                简化报表同步
              </div>
              <div class="text1">
                报表管理，日/周/月报自动同步多维表格，产品信息自动提醒监管者生产数据全局视角；
                <br>
                <a href="#" style="font-size: 15px" @click="changeNav('/mdhc')">了解更多></a>
              </div>

            </div>
            <div class="box box1">
              <img class="img1" src="../assets/dowinPic.png" alt="">
              <div></div>
              <img class="img2" src="../assets/dowinLogo.png" alt="">
              <div>
                使用飞书OKR <br>
                上下对齐目标
              </div>
              <div class="text1">
                用飞书OKR对齐战略目标，让组织的全员都能够明确组织的核心目标、关键决策和平衡结果，并且以此来确定自己的工作任务和目标；
                <br>
                <a href="#" style="font-size: 15px" @click="changeNav('/wipView')">了解更多></a>
              </div>

            </div>
          </div>
          <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
        </div>
      </div>
      <div class="section4" style="background-color:#f6fbfd;">
        <div class="sub3">
          <span class="sub3title">众多品牌一致认可</span>
          <div class="imageBg">
            <img src="../assets/Logos.png" alt="">
          </div><!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactUs from "@/components/ContactUs.vue";
// import DigitalProduct from "@/components/DigitalProduct.vue";

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',

  // components: {DigitalProduct},
  data() {
    return {
      param: "digital",
      isComponentModalActive: false,
      carousels: [
        { text: 'Slide 1', color: 'primary' },
        { text: 'Slide 2', color: 'info' },
        { text: 'Slide 3', color: 'success' },
        { text: 'Slide 4', color: 'warning' },
        { text: 'Slide 5', color: 'danger' }
      ],
      arrow: true,
      arrowBoth: false,
      arrowHover: false,
      iconPack: 'uil',
      iconPrev: 'arrow-left',
      iconNext: 'arrow-right',
      iconSize: '',
    }
  },
  components: {
    // HelloWorld
    ModalForm: ContactUs
  },
  methods: {
    change(param) {
      console.log(param);
      this.param = param;
    },
    changeNav(param){
      this.$router.push(param)
    }
  }

}
</script>
<style scoped>

.contactUs3{
  margin-left: 5rem;
  //margin-bottom: -50px;
}
.img1{
  width: 300px;
  height: 200px;
  border-radius: 10px;
}
.img2{
  height: 40px;
  margin-left: 12px;
}
.subtitle1{
  font-size: 22px;
  font-weight: bold;
}
.imageBg{
  margin: 40px auto;
  justify-content: center;
  text-align: center;
}
.myBox {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
}

.box1 {
  margin: 0 auto;
  width: 301px;
  height: 490px;
  padding: 0;
}

.box1 > div {
  font-size: 20px;
  font-weight: bold;
  margin: 0 12px;

}

.box1 > .text1 {
  font-size: 18px;
  font-weight: normal;

}

.centeredWord {
  display: block;
  margin-top: 20px;
  text-align: left;
  padding-left: 95px;
  padding-right: 50px;
  /*width: 50px;*/
  white-space: pre-wrap;
  font-size: 16px;
  line-height: 32px;
}

.bgimage {
  width: 466px;
  height: 240px;
  /*float: inside;*/
  display: block;
}

.textLogo {
  position: absolute;
  top: 30px;
  /*margin: 0 auto;*/
  left: 40%;

}

.sub3title {
  display: block;
  font-size: 44px;
  font-weight: bold;
  text-align: center;
  line-height: 55px;
}

.headTitle3 {
  display: block;
  font-weight: initial;
  text-align: center;
  font-size: 40px;
}

.sub3 {
  display: block;
  margin: 30px auto;
  font-size: 44px;
  
}

.buttonMargin {
  margin-top: -80px;
  margin-bottom: 50px;
}

.strong {
  font-weight: bold;
}

.digitalOl {
  margin-top: 2rem;
  width: 32rem;
  font-size: 16px;
}

.digitalOl > ol {
  list-style-type: inherit;
  line-height: 30px;

}

.digitalOl > ol li {
  margin-bottom: 25px;
}

.digitalOl > ol li::marker {
  color: #4099FF;
}

.contactUs1 {
  margin: 20px auto;
}

.adv {
  margin-left: 5.5rem;
  display: flex;
  flex-direction: row;
}

.lanjingSecond {
  display: block;
  font-size: 30px;
  font-weight: bold;
  line-height: 55px;
}

.title1 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin: 30px 0;
}

.subA {
  margin-top: 1rem;
}

.tabSize {
  /*padding: 0 30rem;*/
}

.logo {
  position: relative;
  top: 2px;
  /*margin-top: 2rem;*/
  height: 60px;
}

.subsection {
  display: flex;
  flex-direction: row;
  flex-flow: nowrap;
  flex-wrap: nowrap;
  white-space: nowrap;
  width: 100%;
  padding: 0 50px;
  /*margin: 0 auto;*/
}

.subsection1 {
  display: flex;
  flex-direction: row;
  flex-flow: nowrap;
  flex-wrap: nowrap;
  white-space: nowrap;
  width: 100%;

  /*margin: 0 auto;*/
}

.centered {
  background-image: url("../assets/ABUIABAEGAAgj-25oAYogLKDYzDUAziEAg.png");
  background-size: 100% 250px;
  background-repeat: no-repeat;
  /*border: white 2px solid;*/
  /*border-radius: 15px;*/
  white-space: nowrap;
  position: relative;
  top: 18rem;
  margin: 0 auto;
  width: 30%;
  height: 256px;
  text-align: center;
  /*overflow: hidden;*/
}

.centered1 {
  background-image: url("../assets/ABUIABAEGAAgzZCsoAYorOPKmQEw_wI45QM.png");
  background-size: 100% 537px;
  background-repeat: no-repeat;
  /*border: white 2px solid;*/
  /*border-radius: 15px;*/
  white-space: nowrap;
  position: relative;
  /*top: 18rem;*/
  margin: 0 auto;
  width: 30%;
  height: 537px;
  text-align: center;
  /*overflow: hidden;*/
}
.centeredMo{
  margin: 0 auto;
}

.centered > div {
  margin-top: 50px;
}

.centered1 > div {
  margin-top: 50px;
  margin-left: 40px;
}

.centeredTitle {
  text-align: center;
  display: block;
  margin-top: 50px;
  margin-left: 40px;
  line-height: 50px;
  font-size: 32px;
  font-weight: bold;
}

.section1 {
  /*margin-top: 100px;*/
  /*margin-left: 50px;*/
  line-height: 80px;
  height: 870px;
  background-image: url("../assets/banner3.png");
  /*background-color: blue;*/
  background-size: cover;
}

.section12 {
  /*margin-top: 100px;*/
  /*margin-left: 50px;*/
  line-height: 80px;
  height: 870px;
  background-image: url("../assets/banner4_clipdrop-enhance.png");
  /*background-color: blue;*/
  background-size: cover;
}
.section12 > .subT {
//position: relative;
//top: 100px;
  padding-top: 100px;
  padding-left: 5rem;
//left: 5rem;
}
.section1 > .subT {
  //position: relative;
  //top: 100px;
  padding-top: 100px;
  padding-left: 5rem;
  //left: 5rem;
}

.lanjing {
  display: block;
  font-size: 44px;
  /*margin-top: 200px;*/
  font-weight: bold;
}


#head {
  display: block;
  font-weight: bold;
  font-size: 48px;
}

.headTitle {
  font-size: 25px;

}
.buttons1{
  justify-content: center;
  text-align: center;
}

</style>
