<template>
  <div class="support">
    <div class="p1">
      <div class="texts">
        <h1>提供优质的落地服务与技术支持</h1>
        <h1>打造企业一站式数字化办公平台</h1>
      </div>
    </div>
<!--    <div class="p2">
      <div>
        <div class="myBox">
          <div class="texts1">
            <div class="h1">谢扩展：飞书企业效能顾问</div>
            <div class="h2">原钉钉学院培训讲师
              </div>
            <div class="h2">钉钉高级部署专家</div>
            <div class="h2">擅长企业咨询、诊断、业务流程梳理</div>
            <div class="h2">擅长飞书专项SKU、效率先锋培训</div>
          </div>
          <img src="../assets/boss.jpg" alt="">
        </div>
      </div>
    </div>-->
    <div class="p2">
      <div>
        <div class="myBox">
          <div class="texts1">
            <div class="h1">7X24小时-全方位提供支持</div>
            <div class="h2">篮鲸为公司级、企业级客户提供7*24小时的咨询、问答服务，随时解答客户关于产品使用，系统落地等疑问</div>
          </div>
          <img src="../assets/business11.png" alt="">
        </div>
      </div>
    </div>
    <div class="p2">
      <div>
        <div class="myBox">
          <img src="../assets/business22.png" alt="">
          <div class="texts1">
            <div class="h1">售前咨询-全行业最佳实践</div>
            <div class="h2">篮鲸全面深入，重视客户需求，以服务为导向，按行业最佳实践全面构建客户体验，为客户提供专业、有效的售前咨询</div>
          </div>

        </div>
      </div>
    </div>
    <div class="p2">
      <div>
        <div class="myBox">
          <div class="texts1">
            <div class="h1">客户成功-落地效果保障</div>
            <div class="h2">从组织架构导入和企业认证，到系统建设和场景搭建，再到上线培训，蓝鲸通过采用有效的跟踪机制，建立可靠的效果监测体系，做到落地效果有保障</div>
          </div>
          <img src="../assets/business33.png" alt="">
        </div>
      </div>
    </div>
    <div class="p2">
      <div>
        <div class="myBox">
          <img src="../assets/business44.png" alt="">
          <div class="texts1">
            <div class="h1">客服中心-在线客服支持</div>
            <div class="h2">我们竭尽全力为客户提供最优质的客服支持，以飞书群聊、服务台等为载体，用我们的专业知识和技术来为客户解决问题</div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SupportView"
}
</script>

<style scoped>
.myBox > img{
  margin: 0 auto;
  height: 400px;
}
.texts1 > .h1{
  display: block;
  font-size: 32px;
  font-weight: bold;
}
.texts1 > .h2{
  display: block;
  font-size: 20px;
  font-weight: bold;

}
.texts1{
  margin: 100px auto;
  width: 450px;
}
.p2 > h1 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin: 30px 0;
}
.texts {
  padding: 11rem;
  height: 30rem;
  text-align: center;
  justify-content: center;
}
.myBox {
  /*margin-top: 50px;*/
  display: flex;
  margin: 50px auto;
  flex-direction: row;
}

.p1 {
  background-image: url("../assets/ABUIABAEGAAgi7LgoAYousbP4gUwoAs4pAM.png");
  background-size: cover;
  height: 30rem;
}
.texts > h1 {
  font-size: 44px;
  font-weight: bold;
}

</style>