<script>
import {defineComponent} from 'vue'
import ModalForm from "@/components/ContactUs.vue";
// import ContactUs from "@/components/ContactUs.vue";

const customIconConfig = {
  customIconPacks: {
    'fas': {
      sizes: {
        'default': '',
        'is-small': 'fa-xs',
        'is-medium': 'fa-lg',
        'is-large': 'fa-2x'
      }
    },
    'ionicons': {
      sizes: {
        'default': 'is-size-5',
        'is-small': '',
        'is-medium': 'is-size-3',
        'is-large': 'is-size-1'
      },
      iconPrefix: 'ion-md-',
      internalIcons: {
        'check': 'checkmark',
        'information': 'information',
        'check-circle': 'checkmark-circle-outline',
        'alert': 'alert',
        'alert-circle': 'alert',
        'arrow-up': 'arrow-up',
        'chevron-right': 'arrow-forward',
        'chevron-left': 'arrow-back',
        'chevron-down': 'arrow-down',
        'eye': 'eye',
        'eye-off': 'eye-off',
        'menu-down': 'arrow-dropdown',
        'menu-up': 'arrow-dropup',
        'close-circle': 'close-circle-outline'
      }
    },
    'uil': {
      sizes: {
        'default': 'is-size-5',
        'is-small': '',
        'is-medium': 'is-size-3',
        'is-large': 'is-size-1'
      },
      iconPrefix: 'uil-',
      internalIcons: {
        'check': 'check',
        'information': 'information',
        'check-circle': 'checkmark-circle',
        'alert': 'exclamation',
        'alert-circle': 'exclamation-circle',
        'arrow-up': 'arrow-up',
        'chevron-right': 'angle-right',
        'chevron-left': 'angle-left',
        'chevron-down': 'arrow-down',
        'eye': 'eye',
        'eye-off': 'eye-slash',
        'menu-down': 'angle-down',
        'menu-up': 'angle-up',
        'close-circle': 'times-circle'
      }
    }
  }
}

export default defineComponent({
  name: "ProjectManagement",
  data() {
    return {
      param: "digital",
      isComponentModalActive: false,
    }
  },
  components: {ModalForm},
  created() {
    this.$buefy.config.setOptions(customIconConfig)
  }
})
</script>

<template>
  <div class="project">
    <div class="p1">
      <div class="texts">
        <h1>飞书项目管理</h1>
        <h2>单个平台解决多种项目管理问题</h2>
      </div>
    </div>
    <div class="p2">
      <div>
        <div class="myBox">
          <div class="texts1">
            <div class="h1">明晰流程每一步
              轻松产出好结果

            </div>
            <!--            <div class="h2">蓝鲸为公司级、企业级客户提供7*24小时的咨询、问答服务，随时解答客户关于产品使用，系统落地等疑问</div>-->
          </div>
          <div class="module"><img src="../assets/project.png" style="width: auto" alt=""></div>
        </div>
      </div>
    </div>
    <div class="p2">
      <h1>为复杂场景打造的专业项目管理平台</h1>
      <div>
        <div class="tabSize">
          <b-tabs position="is-centered" class="block" type="is-toggle-rounded" animated>
            <b-tab-item class="centeredMo" label="管理清晰">
              <div class="adv">
                <div>
        <span class="lanjingSecond">流程有序拆解，
<br>
牵引项目成功交付</span>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">用流程呈现项目关系</div>
                        <div class="passage">
                          支持复杂业务流程，不同角色获取相关信息，<br>快速同步任务安排
                        </div>
                      </li>
                      <li>
                        <div class="strong">迅速定位项目最新进展</div>
                        <div class="passage">
                          机器人、群聊等多种提醒方式，不错过任何重要事项，有效控制风险事项
                        </div>
                      </li>
                      <li>
                        <div class="strong">确保落地降低项目风险</div>
                        <div class="passage">
                          高效风险提醒，直观显示人员排期，高效协同避免重复劳动
                        </div>
                      </li>

                    </ol>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <!--                  <img src="../assets/BiTable11.png" alt="">-->
                  <video src="../assets/project1.mp4" autoplay loop></video>
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" label="问题总结" @click='change("saas")'>
              <div class="adv">
                <div>
        <span class="lanjingSecond">多视图追踪透视，<br>
持续提升项目质量</span>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">多种视图查看项目</div>
                        <div class="passage">
                          散落的数据集中管理，直观感受各类进度，让有价值的信息高速流转
                        </div>
                      </li>
                      <li>
                        <div class="strong">挖掘项目深层问题</div>
                        <div class="passage">
                          各类视图深掘底层问题，轻松发现深层缺陷
                        </div>
                      </li>
                      <li>
                        <div class="strong">持续提升项目表现</div>
                        <div class="passage">
                          不同视图挖掘出不同问题，使项目管理疑难杂症暴露无余
                        </div>
                      </li>

                    </ol>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <!--                  <img src="../assets/dashBoard.png" alt="">-->
                  <video src="../assets/project2.mp4" autoplay loop></video>
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" label="简化操作" @click="change('system')">
              <div class="adv">
                <div>
        <span class="lanjingSecond">自动化免操作，<br>
省人力更专注</span>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">高频流程自动化</div>
                        <div class="passage">
                          支持重复的流程自动化处理，设置一次一劳永逸
                        </div>
                      </li>
                      <li>
                        <div class="strong">告别重复人工操作</div>
                        <div class="passage">
                          告别重复劳动，使精力放在重要事项上
                        </div>
                      </li>
                      <li>
                        <div class="strong">专注高价值工作</div>
                        <div class="passage">
                          关注高价值工作，不再受繁琐的重复工作干扰
                        </div>
                      </li>

                    </ol>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <video src="../assets/project3.mp4" autoplay loop></video>
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" label="信息集成" @click="change('system')">
              <div class="adv">
                <div>
        <span class="lanjingSecond">全团队、多角色，<br>
用一个飞书就够了</span>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">打通信息孤岛</div>
                        <div class="passage">
                          飞书是集成性强的企业级应用，轻松集成飞书项目，实现信息互通和数据共享
                        </div>
                      </li>
                      <li>
                        <div class="strong">随时随地用手机就能查看最新消息</div>
                        <div class="passage">
                          多平台应用多种方案通知，信息不再滞后
                        </div>
                      </li>

                    </ol>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <video src="../assets/project4.mp4" autoplay loop></video>
                </div>
              </div>
            </b-tab-item>
          </b-tabs>

        </div>
      </div>
    </div>
    <div class="p2">
      <h1>更好地帮助团队中的每个角色

      </h1>
      <div>
        <div class="tabSize">
          <b-tabs position="is-centered" class="block" animated>
            <b-tab-item class="centeredMo" icon-pack="uil" icon="user" label="业务管理者">
              <div class="adv">
                <div>
                  <span class="lanjingSecond"></span>
                  <div class="gap"></div>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">去繁就简，把控项目最新进展</div>
                        <div class="passage">
                          多种可视化看板，随时把控项目进度，发现问题，灵活调配资源
                        </div>
                      </li>
                      <li>
                        <div class="strong">智能把控，规避项目风险</div>
                        <div class="passage">
                          关键风险节点智能介入，自动拉群同步项目上下文，确保项目端到端落地
                        </div>
                      </li>


                    </ol>
                    <b-button class="button is-primary contactUs1"
                              label="查看解决方案"
                              type="is-primary"
                              style="margin-top: 0;margin-bottom: 30px"
                              size="is-medium"
                              @click="isComponentModalActive = true"/>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/projectS1.png" alt="">
                  <!--                  <video src="../assets/project1.mp4" autoplay loop></video>-->
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" icon-pack="uil" icon="cube" label="产品经理" @click='change("saas")'>
              <div class="adv">
                <div>
                  <span class="lanjingSecond"></span>
                  <div class="gap"></div>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">清晰聚合，用户声音在手边</div>
                        <div class="passage">
                          完整沉淀、聚合用户声音，创造贴合用户好产品
                        </div>
                      </li>
                      <li>
                        <div class="strong">过程可见，辅助项目决策质量</div>
                        <div class="passage">
                          投票收集多方视角，透明需求决策过程，辅助做出好判断
                        </div>
                      </li>


                    </ol>
                    <b-button class="button is-primary contactUs1"
                              label="查看解决方案"
                              type="is-primary"
                              style="margin-top: 0;margin-bottom: 30px"
                              size="is-medium"
                              @click="isComponentModalActive = true"/>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/projectS2.png" alt="">
                  <!--                  <video src="../assets/project2.mp4" autoplay loop></video>-->
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" icon-pack="uil" icon="monitor" label="工程师" @click="change('system')">
              <div class="adv">
                <div>
                  <span class="lanjingSecond"></span>
                  <div class="gap"></div>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">一图拉齐，免去繁琐反复沟通</div>
                        <div class="passage">
                          可通过流程图清晰了解项目上下文，免去繁琐信息沟通
                        </div>
                      </li>
                      <li>
                        <div class="strong">省心省力，专注高价值工作</div>
                        <div class="passage">
                          开放能力灵活支持多种插件，自动化处理工作中的高频重复动作，专注高价值内容
                        </div>
                      </li>


                    </ol>
                    <b-button class="button is-primary contactUs1"
                              label="查看解决方案"
                              type="is-primary"
                              style="margin-top: 0;margin-bottom: 30px"
                              size="is-medium"
                              @click="isComponentModalActive = true"/>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <!--                  <video src="../assets/project3.mp4" autoplay loop></video>-->
                  <img src="../assets/projectS3.png" alt="">
                </div>

              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" icon-pack="uil" icon="list-ui-alt" label="项目经理"
                        @click="change('system')">
              <div class="adv">
                <div>
                  <span class="lanjingSecond"></span>
                  <div class="gap"></div>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">大幅提升 PMO 人效比至 1:200</div>
                        <div class="passage">
                          用流程高效牵引团队，还能根据“最佳流程”灵活裁剪，适应个性化需求，大幅提升项目管理人效
                        </div>
                      </li>
                      <li>
                        <div class="strong">抓大放小，从“追项目”到“管项目”</div>
                        <div class="passage">
                          多种视图、度量工具直接观察项目状态，聚焦项目核心价值管理
                        </div>
                      </li>

                    </ol>
                    <b-button class="button is-primary contactUs1"
                              label="查看解决方案"
                              type="is-primary"
                              style="margin-top: 0;margin-bottom: 30px"
                              size="is-medium"
                              @click="isComponentModalActive = true"/>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/projectS4.png" alt="">
                  <!--                  <video src="../assets/project4.mp4" autoplay loop></video>-->
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" icon-pack="uil" icon="briefcase-alt" label="商务" @click="change('system')">
              <div class="adv">
                <div>
                  <span class="lanjingSecond"></span>
                  <div class="gap"></div>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">规范动作，团队管理更科学</div>
                        <div class="passage">
                          助力复杂的销售流程标准化，赋能销售团队管理升级
                        </div>
                      </li>
                      <li>
                        <div class="strong">一处沉淀，关键信息不丢失</div>
                        <div class="passage">
                          将销售生命周期中的关键信息沉淀在流程中，各角色快速获取关键信息
                        </div>
                      </li>

                    </ol>
                    <b-button class="button is-primary contactUs1"
                              label="查看解决方案"
                              type="is-primary"
                              style="margin-top: 0;margin-bottom: 30px"
                              size="is-medium"
                              @click="isComponentModalActive = true"/>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/projectS5.png" alt="">
                  <!--                  <video src="../assets/project4.mp4" autoplay loop></video>-->
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" icon-pack="uil" icon="monitor-heart-rate" label="运营"
                        @click="change('system')">
              <div class="adv">
                <div>
                  <span class="lanjingSecond"></span>
                  <div class="gap"></div>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">便捷反馈，避免人工搬运</div>
                        <div class="passage">
                          通过开放能力，与飞书IM 等反馈平台打通，通过对话直接创建反馈工单。
                        </div>
                      </li>
                      <li>
                        <div class="strong">进度可视，直观获取需求进展</div>
                        <div class="passage">
                          将反馈管理拆解为可视化节点流程，上下游信息互通，分工、排期一目了然。
                        </div>
                      </li>

                    </ol>
                    <b-button class="button is-primary contactUs1"
                              label="查看解决方案"
                              type="is-primary"
                              style="margin-top: 0;margin-bottom: 30px"
                              size="is-medium"
                              @click="isComponentModalActive = true"/>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/projectS.png" alt="">
<!--                  <video src="../assets/project4.mp4" autoplay loop></video>-->
                </div>
              </div>
            </b-tab-item>
          </b-tabs>

        </div>
      </div>
    </div>
    <div class="p2 p4">
      <h1>最快 <span style="color: #004cff"> 1 天 </span>即可迁移至飞书项目</h1>
      <div>
        <div class="projectLast">
          <b-button class="button is-primary contactUs1"
                    label="预约演示"
                    type="is-primary"
                    style="margin-top: 0;margin-bottom: 30px"
                    size="is-medium"
                    @click="isComponentModalActive = true"/>

          <b-modal
              v-model="isComponentModalActive"
              has-modal-card
              trap-focus
              :destroy-on-hide="false"
              aria-role="dialog"
              aria-label="Example Modal"
              close-button-aria-label="Close"
              aria-modal>
            <template #default="props">
              <modal-form v-bind="formProps" @close="props.close"></modal-form>
            </template>
          </b-modal>
        </div>
        <div class="projectLast">
          <img src="../assets/projectLast.png" alt="">

        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>
@import "https://unpkg.com/ionicons@4.5.10-0/dist/css/ionicons.min.css";
@import "https://unicons.iconscout.com/release/v2.1.11/css/unicons.css";
.p4{
  background-image: url("../assets/projectBg.png");
  background-size: cover;
}
.projectLast{
  align-content: center;
  text-align: center;
  //padding: 30px;
}
.gap {
  margin-top: 8rem;
}

img {
  width: 820px;
}

video {
  width: 820px;
  height: 590px;
}

.centeredMo {
  margin: 0 auto;
}

.myBox > .module {
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}

.texts1 > .h1 {
  display: block;
  font-size: 44px;
  font-weight: bold;
}

.texts1 > .h2 {
  display: block;
  font-size: 20px;
  font-weight: bold;

}

.texts1 {
  margin: 50px auto 10px;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.templates {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
//text-align: center;
}

.templates > div {
  margin: 1rem;
}

.box1 {
  margin: 0 auto;
  width: 301px;
  height: 400px;
  padding: 0;
  cursor: pointer;
}

.box1 > div {
  font-size: 20px;
  font-weight: bold;
  margin: 8px 12px;

}

.box1 > .text1 {
  font-size: 18px;
  font-weight: normal;

}

.box1 > img {
  border-radius: 10px;
}

.strong {
  font-weight: bold;
  font-size: 20px;
  width: 700px;

}


.digitalOl {
  margin-top: 2rem;
  width: 32rem;
  font-size: 16px;
}

.digitalOl > ol {
  list-style-type: inherit;
  line-height: 30px;

}

.digitalOl > ol li {
  margin-bottom: 25px;
}

.digitalOl > ol li > .passage {
  margin-top: 8px;
  font-size: 16px;
  width: 400px;
}

.digitalOl > ol li::marker {
  color: #4099FF;
}

.contactUs1 {
  margin: 20px auto;
}

.adv {
  margin-left: 5.5rem;
  display: flex;
  flex-direction: row;
}

.lanjingSecond {
  display: block;
  font-size: 30px;
  font-weight: bold;
  line-height: 40px;
}

.p2 > h1 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin: 30px 0;
}

.subA {
  margin-top: 1rem;
}


.tabSize {
  /*padding: 0 30rem;*/
}

.p1 {
  background-image: url("../assets/outputBG2.png");
  background-size: cover;
  height: 30rem;
}

.texts {
  padding: 11rem;
  height: 30rem;
  text-align: center;
  justify-content: center;
}

.texts > h1 {
  font-size: 44px;
  font-weight: bold;
}

.texts > h2 {
  font-size: 38px;
}

.p2 {
  background-color: #F9FAFF;
}
.project{
  background-color: #F9FAFF;

}
</style>