<template>
  <div>
    <form action="">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">联系我们</p>
          <button
              type="button"
              class="delete"
              @click="$emit('close')"/>
        </header>
        <section class="modal-card-body">
          <b-field label="姓名">
            <b-input
                type="name"
                v-model="name"
                placeholder="请输入您的姓名"
                required>
            </b-input>
          </b-field>
          <b-field label="联系方式">
            <b-input
                type="phone"
                v-model="phone"
                placeholder="请输入您的手机号码"
                required>
            </b-input>
          </b-field>
          <b-field label="公司名称">
            <b-input
                type="cropName"
                v-model="cropName"

                placeholder="请输入您的公司名称"
                required>
            </b-input>
          </b-field>
          <b-field label="咨询产品">
            <b-checkbox v-model="checkBox" native-value="飞书">飞书</b-checkbox>
            <b-checkbox v-model="checkBox" native-value="定制方案">定制方案</b-checkbox>
            <b-checkbox v-model="checkBox" native-value="多维表格">多维表格</b-checkbox>
            <b-checkbox v-model="checkBox" native-value="项目管理">项目管理</b-checkbox>
          </b-field>
          <b-field label="是否使用过其他业务系统">
<!--            <input type="radio" v-model="isUsed" value="是">是-->
            <b-radio name="name" v-model="isUsed"  native-value="是">是</b-radio>
            <b-radio name="name" v-model="isUsed"  native-value="否">否</b-radio>
          </b-field>

        </section>
        <footer class="modal-card-foot">
          <b-button
              label="关闭"
              @click="$emit('close')"/>
          <b-button
              label="提交"
              @click="sub();$emit('close')"
              type="is-primary"/>
        </footer>
      </div>
    </form>
  </div>

</template>

<script>
export default {
  name: "ContactUs",
  // props: ['name', 'phone', 'cropName'],
  data() {
    return {
      name: "",
      phone: "",
      cropName: "",
      token: "",
      isUsed: "否",
      checkBox:[],


    }
  },
  created() {
    this.getToken();
  },
  methods: {
    async getToken() {
      const data = JSON.stringify({
        "app_id": "cli_a413bc7cb339900c",
        "app_secret": "IQdW2KENZt3YaBpLDOfYeeTPFbEvjIXv"
      });
      await this.$axios({
        method: 'POST',
        url: 'feishu/open-apis/auth/v3/tenant_access_token/internal',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }).then((response) => {
        // 处理成功情况
        console.log(response);
        this.token = response.data.tenant_access_token;
      })
          .catch((error) => {
            // 处理错误情况
            console.log(error);
          })
          .then(function () {
            // 总是会执行
            console.log("总是会执行")
          });

    },
    sub() {
      if (this.name == '' || this.phone == '' || this.cropName == ''){
        this.$buefy.toast.open({
          message: '请填写信息！',
          type: 'is-danger'
        })
        return;
      }
      if (!/^((1[3,5,8][0-9])|(14[5,7])|(17[0,6,7,8])|(19[7]))\d{8}$/.test(this.phone)){
        this.$buefy.toast.open({
          message: '手机号码填写不正确！',
          type: 'is-danger'
        })
        return;
      }
      const  data = JSON.stringify({
        "fields": {
          "姓名": this.name,
          "联系方式": this.phone,
          "公司名称": this.cropName,
          "是否使用过其他业务系统": this.isUsed,
          "咨询产品":this.checkBox
        }
      })

      this.$axios({
        method: 'POST',
        url: 'feishu/open-apis/bitable/v1/apps/RDUIb2UTdaN5TZsye1Ich1itnAw/tables/tbl3dnjjcRIBmpcV/records',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.token
        },
        data: data
      }).then((response) => {
        // 处理成功情况
        console.log(response);
        this.$buefy.toast.open({
          message: '提交成功',
          type: 'is-success'
        })

      })
          .catch((error) => {
            // 处理错误情况
            console.log(error);
            this.$buefy.toast.open({
              message: '提交失败',
              type: 'is-danger'
            })
          })
          .then(function () {
            // 总是会执行
            console.log("总是会执行")
            // this.$emit('close')
          });
    }
  }


}
</script>

<style scoped>
.modal-card-body{
  font-size: initial;
  text-align: left;
  color: initial;
}
</style>