<template>
  <div class="biTableModal">
    <form action="">
      <div class="modal-card" >
<!--        <header class="modal-card-head">-->
<!--          <p class="modal-card-title"></p>-->
<!--&lt;!&ndash;          <button&ndash;&gt;-->
<!--&lt;!&ndash;              type="button"&ndash;&gt;-->
<!--&lt;!&ndash;              class="delete"&ndash;&gt;-->
<!--&lt;!&ndash;              @click="$emit('close')"/>&ndash;&gt;-->
<!--        </header>-->
        <section class="modal-card-body">
          <div class="titles">
            <img :src="getImages" alt="" class="images">
            <div class="title1">
              <h1>{{ title }}</h1>
              <b-field>
                <b-tag rounded type="is-warning is-light">{{ tagName1 }}</b-tag>
                <b-tag rounded type="is-primary is-light">{{tagName2}}</b-tag>
              </b-field>
              <h2>{{ intro }}</h2>
              <div class="buttons">
                <b-button type="is-primary" outlined @click="goTemplate">使用模板</b-button>
<!--                <b-button type="is-primary">预约顾问讲解</b-button>-->
              </div>
            </div>

          </div>
          <div class="divide" >模板预览</div>
          <div>
            <iframe class="heraComp_iframe" frameborder="0" :src="iframeSrc"></iframe>
          </div>
        </section>

      </div>
    </form>

  </div>

</template>

<script>


export default {
  name: "BiTableView",
  props: ['images', 'title', 'tagName1','tagName2','intro','templateSrc','iframeSrc'],
  data() {
    return {

      token: "",
      isUsed: "否",
      checkBox:[],


    }
  },

  methods: {
    goTemplate(){
      window.open(this.templateSrc,'_blank') // 在新窗口打开外链接
      // window.location.href =this.templateSrc;  //在本页面打开外部链接
    }
  },
  computed: {
    getImages(){
      return require('@/assets/'+this.images)
    }
  }



}
</script>

<style scoped>
.divide{
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 14px;
}
iframe{
  width: 100%;
  height: 100vh;
}
.title1{
  margin-left: 1rem;
}
.title1 > h1{
  display: block;
  font-size: 20px;
  line-height: 28px;
  //margin-left: 1rem;
  margin-top: 12px;
  margin-bottom: 12px;
  color: black;
  font-weight: bold;

}
.title1 > h2{
  display: block;
  font-size: 14px;
  line-height: 22px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.titles{
  height: 12rem;
  margin: 5px;
  display: flex;
}
.images{
  height: 11rem;
  border-radius: 10px;
}
.biTableModal{

  padding: 0;
}
.modal-card{
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;

}
.modal-card-body{
  margin: 0;
  font-size: initial;
  text-align: left;
  color: initial;
  

}
</style>