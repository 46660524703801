<script>


let socket;

export default {
  name: 'ContactOnline',
  data() {
    return {
      // 登录用户
      user: '',
      // 消息记录列表
      msgList: [],
      // 发送的消息
      message: {
        time:null,//时间
        to: '',//发给谁
        from: '',
        msg: ''
      },
      // 在线用户列表
      userList: []
    }
  },
  methods: {
    init() {
      // 如果sessionStorage中没有用户信息，则跳转登录页面
      // this.user = sessionStorage.getItem('user')
      this.user = '用户' + Math.round(Math.random()*1000)
      if (!this.user) {
        this.$router.push('/')
      }
      let that = this;
      if (typeof (WebSocket) == "undefined") {
        console.log("您的浏览器不支持WebSocket");
      } else {
        console.log("您的浏览器支持WebSocket");
        let socketUrl = "wss://www.lanjingshuzi.cn:443/websocket/socket/" + this.user;
        if (socket != null) {
          socket.close();
          socket = null;
        }
        // 开启一个websocket服务
        socket = new WebSocket(socketUrl);
        //打开事件
        socket.onopen = function () {
          console.log("websocket已打开");
        };
        //  浏览器端收消息，获得从服务端发送过来的文本消息
        socket.onmessage = function (msg) {
          console.log("收到数据====" + msg.data)
          let data = JSON.parse(msg.data)
          if (data.userNames) {
            // userNames存在则是有人登录，获取在线人员信息
            that.userList = data.userNames
          } else {
            // userNames不存在则是有人发消息
            that.msgList.push(data)
          }
        };
        //关闭事件
        socket.onclose = function () {
          console.log("websocket已关闭");
        };
        //发生了错误事件
        socket.onerror = function () {
          console.log("websocket发生了错误");
        }
      }
    },
    send() {
      if (!this.message.msg) {
        this.$message({
          message: '大兄弟，请输入聊天消息！',
          type: 'warning'
        });
      } else {
        if (typeof (WebSocket) == "undefined") {
          console.log("您的浏览器不支持WebSocket");
        } else {
          console.log("您的浏览器支持WebSocket");
          this.message.from=this.user;
          this.message.time=new Date().toLocaleTimeString();
          this.message.to = 'admin'
          socket.send(JSON.stringify(this.message));

          this.message.msg = '';
        }
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<template>
  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">联系我们</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div style="width: 100%;">
          <div style="width: 100%;height: 500px">
            <div style="text-align: center;font-size: 18px;margin-bottom: 10px">在线客服</div>
            <div style="width: 500px;height: 240px;border:1px solid #000000;border-radius: 5px;overflow-y:auto;margin-bottom: 10px">
              <div v-for="(item,index) in msgList" :key="index">
                <!--                {{item.from}}{{item.msg}}{{item.time}}-->
                <div align="right" v-if="item.from===user" style="color: black">{{item.time}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b-tag size="mini" type="is-primary">{{item.from}}</b-tag>
                  <div style="color: black;margin-right: 5px">{{item.msg}}</div></div>
                <div align="left" v-else style="color: black"><b-tag size="mini" type="is-danger">{{item.from}}</b-tag>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{item.time}} <div style="color: black;margin-left: 5px">{{item.msg}}</div></div>
              </div>
            </div>
            <b-input @keyup.enter.native="send" type="textarea" v-model="message.msg" :autosize="{ minRows: 1, maxRows: 3}" placeholder="请输入聊天内容"></b-input>
            <div align="right">
              <b-button type="primary" style="margin-top: 10px" @click="send">发送</b-button>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button
            label="关闭"
            @click="$emit('close')"/>
      </footer>
    </div>
  </form>
</template>

<style scoped>

</style>