<template>
  <div class="digital">
    <div class="p1">
      <div class="texts">
        <h1>数字化产品</h1>
        <h2>打造适配业务的企业专属办公平台</h2>
      </div>
    </div>
    <div class="p2">
      <h1>一站式数字化办公平台</h1>
      <div>
        <div class="tabSize">
          <b-tabs position="is-centered"  class="block" type="is-toggle-rounded" animated>
            <b-tab-item label="日常协同办公" class="centeredMo">
              <div class="adv">
                <div>
        <span class="lanjingSecond">用飞书搞定全部办公场景，
<br>
让信息（任务）更高效的流动</span>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">实时沟通，快速同步事项</div>
                        <div class="passage">
                          支持实时聊天功能，在任何时间、地点与同事交流，<br>快速同步任务安排
                        </div>
                      </li>
                      <li>
                        <div class="strong">智能提醒，有效风险控制</div>
                        <div class="passage">
                          机器人、邮件等多种提醒方式，不错过任何重要事项，有效控制风险事项
                        </div>
                      </li>
                      <li>
                        <div class="strong">高效协同，多人在线编辑</div>
                        <div class="passage">
                          多人实时协同编辑文档，随时@同事评论，高效协同避免重复劳动
                        </div>
                      </li>
                      <li>
                        <div class="strong">日程透视，一键创建任务</div>
                        <div class="passage">
                          随时查看他人日程，合理安排工作，团队可以在一个平台上完成各种任务
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/feishu1.png" alt="">
                </div>
              </div>
            </b-tab-item>
            <b-tab-item label="知识沉淀流转"  class="centeredMo" @click='change("saas")'>
              <div class="adv">
                <div>
        <span class="lanjingSecond">通过云端知识库，<br>
促进结构化的企业知识沉淀与知识增长</span>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">让知识在线，成为企业资产</div>
                        <div class="passage">
                          散落的文档集中管理，新人快速继承团队知识资产，让有价值的信息高速流转
                        </div>
                      </li>
                      <li>
                        <div class="strong">知识在手边，开箱即用</div>
                        <div class="passage">
                          通过全局搜索，快速地获取信息和资源，提高工作效率和质量
                        </div>
                      </li>
                      <li>
                        <div class="strong">知识经验沉淀，避免知识资产流失</div>
                        <div class="passage">
                          将经验文档管理起来，人员的入转调离后，其他成员知识库中获取相关信息
                        </div>
                      </li>
                      <li>
                        <div class="strong">企业知识库促进学习和创新</div>
                        <div class="passage">
                          知识库中收集企业的创新经验和成功案例，为企业未来的发展提供指导
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/document.png" alt="">
                </div>
              </div>
            </b-tab-item>
            <b-tab-item label="音视频即时沟通" class="centeredMo" @click="change('system')">
              <div class="adv">
                <div>
        <span class="lanjingSecond">打破时间与空间的界限，<br>
随时随地开启在线会议</span>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">智能会议室，快速连接电视屏</div>
                        <div class="passage">
                          支持支持手机、电脑、平板、会议室系统一键入会，畅享沉浸式交流
                        </div>
                      </li>
                      <li>
                        <div class="strong">用文档开会，更加清晰高效</div>
                        <div class="passage">
                          会议前预览文档，会议结论沉淀文档，有效跟进任务代办
                        </div>
                      </li>
                      <li>
                        <div class="strong">跨国沟通，一键翻译会议内容</div>
                        <div class="passage">
                          跨国、跨语言沟通，用飞书音视频一键翻译，无需苦恼交流障碍
                        </div>
                      </li>
                      <li>
                        <div class="strong">群聊内容自动同步会议聊天框</div>
                        <div class="passage">
                          事务繁忙没空参加会议，群聊留言即可与参会成员同步沟通
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/record1.png" alt="">
                </div>
              </div>
            </b-tab-item>
            <b-tab-item label="OA审批" class="centeredMo" @click="change('system')">
              <div class="adv">
                <div>
        <span class="lanjingSecond">用飞书进行OA审批，<br>
提高审批透明度和公正性</span>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">审批数据集成，连接其他业务系统</div>
                        <div class="passage">
                          飞书是集成性强的企业级应用，轻松集成CRM、ERP等系统，实现信息互通和数据共享
                        </div>
                      </li>
                      <li>
                        <div class="strong">审批流程可视化，所有节点一目了然</div>
                        <div class="passage">
                          审批流程状态清晰可见，审批人随时查看审批信息，提高了审批的透明度和公正性
                        </div>
                      </li>
                      <li>
                        <div class="strong">设定规则触发，审批过程自动化流转</div>
                        <div class="passage">
                          申请单达到数量或金额时，自动触发审批流程，减少了手动操作的时间和成本
                        </div>
                      </li>
                      <li>
                        <div class="strong">多样化审批方式，满足企业多种需求</div>
                        <div class="passage">
                          多种审批方式，如单人审批、多人审批、依次审批等，满足不同的审批需求
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/approval.png" alt="">
                </div>
              </div>
            </b-tab-item>
          </b-tabs>

        </div>
      </div>
    </div>
    <div class="p2">
      <h1>高效协同赋能业务增长</h1>
      <div>
        <div class="tabSize">
          <b-tabs position="is-centered" class="block" type="is-toggle-rounded" animated>
            <b-tab-item class="centeredMo" label="项目管理">
              <div class="adv">
                <div>
        <span class="lanjingSecond">项目/任务一站式管理，<br>
打造数字化项目管理平台</span>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">任务管理，实时追踪跟进</div>
                        <div class="passage">
                          一键创建任务，为每个任务指定负责人、截止日期和任务描述等
                        </div>
                      </li>
                      <li>
                        <div class="strong">日程管理，创建和分享项目安排</div>
                        <div class="passage">
                          将项目任务、沟通会议和其他重要事件集成到一个统一的日历中
                        </div>
                      </li>
                      <li>
                        <div class="strong">团队协作，与项目成员实时沟通</div>
                        <div class="passage">
                          用云文档共享文件和文档，并通过评论和协作功能进行讨论和反馈
                        </div>
                      </li>
                      <li>
                        <div class="strong">数据分析，沉淀项目经验教训</div>
                        <div class="passage">
                          通过丰富的数据分析工具，跟踪项目进度、成本和风险等关键指标，赋能下一个项目
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/ABUIABAEGAAgjt-LoAYo-J2IgwUwhgY43wQ.png" alt="">
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" label="OKR管理" @click='change("saas")'>
              <div class="adv">
                <div>
        <span class="lanjingSecond">用飞书落地OKR，<br>
帮助公司、团队上下对齐目标、保持协通</span>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">制定目标，聚焦重点</div>
                        <div class="passage">
                          OKR撰写提示，提出鼓舞人心的目标O，并制定对应的关键结果KR
                        </div>
                      </li>
                      <li>
                        <div class="strong">目标对齐，突破边界</div>
                        <div class="passage">
                          公开查询并关注他人OKR，目标关联对齐，用结构化对齐树进行呈现
                        </div>
                      </li>
                      <li>
                        <div class="strong">实时跟进，同步进展</div>
                        <div class="passage">
                          划词评论及@相关人员，围绕OKR快速讨论，消息同步提醒及时对齐
                        </div>
                      </li>
                      <li>
                        <div class="strong">总结反馈，优化策略</div>
                        <div class="passage">
                          用评分量化结果，可视化的数据看板复盘全周期OKR执行情况
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/feishuOkr.png" alt="">
                </div>
              </div>
            </b-tab-item>
            <b-tab-item class="centeredMo" label="人事管理" @click="change('system')">
              <div class="adv">
                <div>
        <span class="lanjingSecond">智能人事自动化管理，<br>
提高人事管理效率、提升信息安全</span>
                  <div class="digitalOl">
                    <ol>
                      <li>
                        <div class="strong">简单好用的人事管理应用</div>
                        <div class="passage">
                          飞书原生应用，帮助企业实现人事流程的自动化管理，提高人事工作效率
                        </div>
                      </li>
                      <li>
                        <div class="strong">轻松进行入转调离管理</div>
                        <div class="passage">
                          线上管理员工的入转调离，跟进追踪不再困难，人事管理效率倍增
                        </div>
                      </li>
                      <li>
                        <div class="strong">智能的人事异动信息提醒</div>
                        <div class="passage">
                          根据不同的人事管理场景，发送人事提醒，有效传达且不打扰
                        </div>
                      </li>
                      <li>
                        <div class="strong">多维度可视化的人事看板</div>
                        <div class="passage">
                          提供多维度人事数据看板，随时了解组织动态、人员分布、入离职率等信息
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
                <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
                <div>
                  <img src="../assets/people.png" alt="">
                </div>
              </div>
            </b-tab-item>

          </b-tabs>

        </div>
      </div>
    </div>
    <div class="p2">
      <h1>产品能力地图</h1>
      <div class="map">
        <img  src="../assets/map1.png" alt="">
      </div>
    </div>
    <div class="p2">
      <h1>集成链接</h1>
      <div class="map">
        <img  src="../assets/allin1.png" alt="">
      </div>
    </div>
    <div class="p2">
      <h1>服务流程图</h1>
      <div class="map">
        <img  src="../assets/liucheng.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DigitalProduct"
}
</script>

<style scoped>
.centeredMo{
  margin: 0 auto;
}

.strong {
  font-weight: bold;
  font-size: 20px;
  width: 700px;

}



.digitalOl {
  margin-top: 2rem;
  width: 32rem;
  font-size: 16px;
}

.digitalOl > ol {
  list-style-type: inherit;
  line-height: 30px;

}

.digitalOl > ol li {
  margin-bottom: 25px;
}

.digitalOl > ol li > .passage{
  margin-top: 8px;
  font-size: 16px;
  width: 400px;
}

.digitalOl > ol li::marker {
  color: #4099FF;
}

.contactUs1 {
  margin: 20px auto;
}

.adv {
  margin-left: 5.5rem;
  display: flex;
  flex-direction: row;
}

.lanjingSecond {
  display: block;
  font-size: 30px;
  font-weight: bold;
  line-height: 40px;
}

.p2 > h1 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin: 30px 0;
}

.subA {
  margin-top: 1rem;
}

.map{
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}

.tabSize {
  /*padding: 0 30rem;*/
}

.p1 {
  background-image: url("../assets/outputBG.jpg");
  background-size: cover;
  height: 30rem;
}

.texts {
  padding: 11rem;
  height: 30rem;
  text-align: center;
  justify-content: center;
}

.texts > h1 {
  font-size: 44px;
  font-weight: bold;
}

.texts > h2 {
  font-size: 44px;
}
</style>