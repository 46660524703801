<template>
  <div class="case">
    <div class="p1">
      <div class="texts">
        <h1>与他们一起选择飞书</h1>

      </div>
    </div>
    <div class="p2">
      <h1>客户案例</h1>
      <div>
        <div class="myBox">
          <div class="box box1">
            <img class="img1" src="../assets/sandiejiPic.png" alt="">
            <div></div>
            <img class="img2" src="../assets/sandiejiLogocn.png" alt="">
            <div>
              智能制造企业 <br>
              多地办公新升级
            </div>
            <div class="text1">
              利用多维表格进行资产管理，使用多维表格扫码盘点资产，快速申请领用；
              <br>
              <a href="#" style="font-size: 15px" @click="goBlank">了解更多></a>
            </div>

          </div>
          <div class="box box1">
            <img class="img1" src="../assets/lingboPic.png" alt="">
            <div></div>
            <img class="img2" src="../assets/lingboLogo.png" alt="">
            <div>
              智能制造企业 <br>
              智能管理车辆数据
            </div>
            <div class="text1">
              用飞书帮助凌博电子建立更高效的工作体系，利用多维表格管理车辆使用信息，抓取最新数据；
              <br>
              <a href="#" style="font-size: 15px" @click="goBlank">了解更多></a>
            </div>

          </div>
          <div class="box box1">
            <img class="img1" src="../assets/meidongPic.png" alt="">
            <div></div>
            <img class="img2" src="../assets/meidongLogo.png" alt="">
            <div>
              收集信息自动化 <br>
              简化报表同步
            </div>
            <div class="text1">
              报表管理，日/周/月报自动同步多维表格，产品信息自动提醒监管者生产数据全局视角；
              <br>
              <a href="#" style="font-size: 15px" @click="changeNav('/mdhc')">了解更多></a>
            </div>

          </div>
          <div class="box box1">
            <img class="img1" src="../assets/dowinPic.png" alt="">
            <div></div>
            <img class="img2" src="../assets/dowinLogo.png" alt="">
            <div>
              使用飞书OKR <br>
              上下对齐目标
            </div>
            <div class="text1">
              用飞书OKR对齐战略目标，让组织的全员都能够明确组织的核心目标、关键决策和平衡结果，并且以此来确定自己的工作任务和目标；
              <br>
              <a href="#" style="font-size: 15px" @click="goBlank">了解更多></a>
            </div>

          </div>



        </div>
        <div class="myBox">
          <div class="box box1">
            <img class="img1" src="../assets/junyingPic.png" alt="">
            <div></div>
            <img class="img2" src="../assets/junyinLogo.png" alt="">
            <div>
              资本投资 <br>
              清晰发现投资问题
            </div>
            <div class="text1">
              使用飞书定制化工作台，无需点击轻松查看每天资金流走向，方便发现问题；
              <br>
              <a href="#" style="font-size: 15px" @click="goBlank">了解更多></a>
            </div>

          </div>
          <div class="box box1">
            <img class="img1" src="../assets/niqianyaPic.png" alt="">
            <div></div>
            <img class="img2" src="../assets/niqianyaLogo.png" alt="">
            <div>
              助力新零售企业 <br>
              降本增效
            </div>
            <div class="text1">
              多维表格做进销存一体化管理，数据汇总整理只需1分钟；一张仪表盘，销售数据变动实时清晰可见；
              <br>
              <a href="#" style="font-size: 15px" @click="goBlank">了解更多></a>
            </div>

          </div>
          <div class="box box1">
            <img class="img1" src="../assets/nanqingPic.jpg" alt="">
            <div></div>
            <img class="img2" src="../assets/nanqingLogo.png" alt="">
            <div>
              飞书审批集成 <br>
              使用飞书人事管理公司组织
            </div>
            <div class="text1">
              有效统一多种系统和流程，利用飞书审批让工作更高效，强化跨部门协作，使用飞书人事快捷管理组织；
              <br>
              <a href="#" style="font-size: 15px" @click="goBlank">了解更多></a>
            </div>

          </div>

          <div class="box box1">
            <img class="img1" src="../assets/zhipuPic.png" alt="">
            <div></div>
            <img class="img2" src="../assets/zhipuLogo.png" alt="">
            <div>
              制造业 <br>
              进销存系统一站式管理
            </div>
            <div class="text1">
              使用飞书多维表格搭建CRM管理系统，
              使用飞书人事帮助客户解决飞书人事问题；
              <br>
              <a href="#" style="font-size: 15px" @click="goBlank">了解更多></a>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerCase",
  methods:{
    changeNav(param) {
      // this.isActive = param;
      this.$router.push(param)
    },

    goBlank(){
      this.$router.push('/wipView')
    }
  }
}
</script>

<style scoped>

.img2{
  //width: 120px;
  margin-left: 12px;
  height: 41px;
}

.img1{
  width: 300px;
  height: 200px;
  border-radius: 10px;
}
.p2{
  margin-bottom: 50px;
}
.texts {
  padding: 11rem;
  height: 30rem;
  text-align: center;
  justify-content: center;
}

.p1 {
  background-image: url("../assets/ABUIABAEGAAgi7LgoAYousbP4gUwoAs4pAM.png");
  background-size: cover;
  height: 30rem;
}

.myBox {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
}

.texts > h1 {
  font-size: 44px;
  font-weight: bold;
}

.p2 > h1 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin: 30px 0;
}

.box1 {
  margin: 0 auto;
  width: 301px;
  height: 464px;
  padding: 0;
}

.box1 > div {
  font-size: 20px;
  font-weight: bold;
  margin: 0 12px;

}

.box1 > .text1 {
  font-size: 18px;
  font-weight: normal;

}
</style>