import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DigitalProduct from "@/views/DigitalProduct.vue";
import CustomerCase from "@/views/CustomerCase.vue";
import SupportView from "@/views/SupportView.vue";
import UniCase from "@/views/UniCase.vue";
import MoreView from "@/views/MoreView.vue";
import BiTable from "@/views/BiTable.vue";
import ProjectManagement from "@/views/ProjectManagement.vue";
import WIPView from "@/views/WIPView.vue";
import AppEngineView from "@/views/AppEngineView.vue";
import MdhcView from "@/views/MdhcView.vue";
import LingboView from "@/views/LingboView.vue";

Vue.use(VueRouter)

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
  return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
  return originalReplace.call(this , location).catch(err=>err)
}


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/digitalProduct',
    name: 'digitalProduct',
    component: DigitalProduct
  },
  {
    path:'/customerCase',
    name:'customerCase',
    component: CustomerCase
  },
  {
    path: '/support',
    name:'supportView',
    component: SupportView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/uniCase',
    name: 'uniCase',
    component: UniCase
  },
  {
    path: '/moreView',
    name: 'moreView',
    component: MoreView
  },
  {
    path: '/biTable',
    name: 'biTable',
    component: BiTable
  },
  {
    path: '/projectManagement',
    name: 'projectManagement',
    component: ProjectManagement
  },
  {
    path: '/wipView',
    name: 'wipView',
    component: WIPView
  },
  {
    path: '/appEngine',
    name: 'appEngineView',
    component: AppEngineView
  },
  {
    path: '/mdhc',
    name: 'mdhcView',
    component: MdhcView
  },
  {
    path: '/lingbo',
    name: 'lingboView',
    component: LingboView
  }

]

const router = new VueRouter({
  routes
})

export default router
