<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "LingboView"
})
</script>

<template>
  <div class="lingbo">

  </div>
</template>

<style scoped>

</style>